import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import SmoothieCard from '../components/Querie_report2';
import '../index.css'; // Adjust the path according to your project structure

function Name_report() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let { data, error } = await supabase.rpc('get_player_medical_reports');
      if (error) {
        console.error(error);
        setError(error);
      } else {
        console.log(data);
        setData(data);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Erro: {error.message}</div>;
  }

  return (
    <div className="page">
      <h2>Home Page</h2>
      <div className="smoothie-grid">
        {data.length > 0 ? (
          data.map((item, index) => (
            <SmoothieCard key={index} item={item} index={index} />
          ))
        ) : (
          <p>Carregando...</p>
        )}
      </div>
    </div>
  );
}

export default Name_report;