/*
import React from 'react'

const Querie_report = ({ item }) => {
  return (
    <div className="smoothie-card">
      <h3>{item.Name}</h3>
      <p>{item.report}</p>
    </div>
  )
}

export default Querie_report
*/

import React from 'react';

const Querie_report = ({ item }) => {
  return (
    <div className="smoothie-card">
      <h3>Report:</h3>
      <p>{item.report}</p>
      <h3>Created at:</h3>
      <p>{item.created_at}</p>
    </div>
  );
};

  export default Querie_report;
