import { useState, useEffect } from "react";
import supabase from "../config/supabaseClient";

const DisplayImages = () => {
  const [images, setImages] = useState([]); // State to store fetched images
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  // Fetch images from Supabase
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data, error } = await supabase
          .from('images') // Your table name
          .select('file_url'); // Select the file_url column

        if (error) {
          throw error;
        }

        setImages(data); // Set fetched images to the state
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []); // Empty dependency array ensures this runs once on component mount

  return (
    <div className="page display-images">
      <h1>Uploaded Images</h1>
      
      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      <div className="images-container">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image.file_url} alt={`Uploaded ${index + 1}`} className="uploaded-image" />
            </div>
          ))
        ) : (
          !loading && <p>No images found.</p>
        )}
      </div>
    </div>
  );
};

export default DisplayImages;
