import { useState } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For redirecting to another page after login

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data: { session }, error } = await supabase.auth.signInWithPassword({ email, password });

      if (error) {
        console.error('Login error:', error); // Debugging error
        setError(error.message);
      } else if (session) {
        console.log('User logged in:', session.user); // Debugging user info
        navigate('/home'); // Redirect to the desired page
      }
    } catch (err) {
      console.error('Unexpected error:', err); // Handle any unexpected errors
      setError('An unexpected error occurred.');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            style={styles.input}
          />
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            style={styles.input}
          />
          <button type="submit" style={styles.button}>Login</button>
          {error && <p style={styles.error}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    background: '#fff',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    width: '100%',
    maxWidth: '300px',
  },
  button: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};

export default Login;
