import supabase from "../config/supabaseClient"
import { Link } from 'react-router-dom'


const SmoothieCard = ({ Players, onDelete }) => {

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from('players')
      .delete()
      .eq('id', Players.id)
    
    if (error) {
      console.log(error)
    }
    if (data) {
      console.log(data)
      onDelete(Players.id)
    }
  }


    return (
      <div className="smoothie-card">
        <h3>{Players.Name}</h3>
        <p>{Players.Position}</p>
        <p>{Players.Birth_date}</p>
      
        <div className="buttons">
        <Link to={"/" + Players.id}>
          <i className="material-icons">edit</i>
        </Link>

        <i className="material-icons" onClick={handleDelete}>delete</i>

        </div>

      </div>
    )
  }
  
  export default SmoothieCard