import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const Create_test = () => {
  const navigate = useNavigate();

  const [test_name, settest_name] = useState('');
  const [formError, setFormError] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if ( !test_name ) {
      setFormError('Please fill in all required fields.');
      return;
    }


    try {
      const { data, error } = await supabase
        .from('tests') // Ensure this matches your actual table name
        .insert([{
          test_name
        }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/tests', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>

          <div className="form-group">
            <label htmlFor="test_name">Test Name:</label>
            <input
              type="text"
              id="test_name"
              value={test_name}
              onChange={(e) => settest_name(e.target.value)}
            />
          </div>

        <div className="form-group">
          <button type="submit">Submit</button>
        </div>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create_test;
