import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import supabase from '../config/supabaseClient';
import '../index2.css';

const InsertGameRatings = () => {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const [summonedPlayers, setSummonedPlayers] = useState([]);
  const [ratings, setRatings] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSummonedPlayers = async () => {
      try {
        // Fetch player_ids who have a call of 'summoned'
        const { data: callData, error: callError } = await supabase
          .from('call_players_game')
          .select('player_id')
          .eq('game_id', gameId)
          .eq('call', 'summoned');

        if (callError) {
          throw callError;
        }

        const playerIds = callData.map(record => record.player_id);

        // Fetch player details based on player_ids
        const { data: playerData, error: playerError } = await supabase
          .from('players')
          .select('id, Name')
          .in('id', playerIds);

        if (playerError) {
          throw playerError;
        }

        setSummonedPlayers(playerData);
        // Initialize ratings state
        const initialRatings = playerData.reduce((acc, player) => {
          acc[player.id] = 1; // Default rating
          return acc;
        }, {});
        setRatings(initialRatings);
      } catch (error) {
        setError('Could not fetch summoned players: ' + error.message);
      }
    };

    fetchSummonedPlayers();
  }, [gameId]);

  const handleRatingChange = (playerId, newRating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [playerId]: newRating
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert ratings to an array of entries
    const ratingEntries = Object.keys(ratings).map(playerId => ({
      game_id: gameId,
      player_id: playerId,
      rating: ratings[playerId]
    }));

    try {
      // Check existing ratings
      const { data: existingRatings, error: fetchError } = await supabase
        .from('game_ratings')
        .select('game_id, player_id')
        .eq('game_id', gameId)
        .in('player_id', Object.keys(ratings));

      if (fetchError) throw fetchError;

      // Prepare separate arrays for insertions and updates
      const existingRatingsSet = new Set(existingRatings.map(({ player_id }) => player_id));
      const toInsert = ratingEntries.filter(({ player_id }) => !existingRatingsSet.has(player_id));
      const toUpdate = ratingEntries.filter(({ player_id }) => existingRatingsSet.has(player_id));

      // Perform insertions
      if (toInsert.length > 0) {
        const { error: insertError } = await supabase
          .from('game_ratings')
          .insert(toInsert);
        if (insertError) throw insertError;
      }

      // Perform updates
      for (const rating of toUpdate) {
        const { error: updateError } = await supabase
          .from('game_ratings')
          .update({ rating: rating.rating })
          .eq('game_id', rating.game_id)
          .eq('player_id', rating.player_id);
        if (updateError) throw updateError;
      }

      navigate(-1); // Go back to the previous page
    } catch (error) {
      setError('Error inserting game ratings: ' + error.message);
    }
  };

  return (
    <div className="page insert-game-ratings">
      <style>{`
        .page.insert-game-ratings {
          padding: 20px;
        }

        .player-ratings {
          display: flex;
          flex-direction: column;
        }

        .player-rating {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .player-name {
          flex: 1;
          font-weight: bold;
        }

        .rating-slider-container {
          display: flex;
          align-items: center;
          flex: 2;
        }

        .rating-slider {
          margin-right: 10px;
        }

        .rating-value {
          min-width: 30px;
          text-align: center;
        }
      `}</style>
      <h2>Insert Game Ratings</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        {summonedPlayers.length === 0 ? (
          <p>No players to rate.</p>
        ) : (
          <div className="player-ratings">
            {summonedPlayers.map(player => (
              <div key={player.id} className="player-rating">
                <span className="player-name">{player.Name}</span>
                <div className="rating-slider-container">
                  <input
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={ratings[player.id] || 1}
                    onChange={(e) => handleRatingChange(player.id, parseInt(e.target.value))}
                    className="rating-slider"
                  />
                  <span className="rating-value">{ratings[player.id]}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        <button type="submit">Submit Ratings</button>
      </form>
    </div>
  );
};

export default InsertGameRatings;
