import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const GameDetailPage = ({ gameId, onBack }) => {
  const [gameDetails, setGameDetails] = useState(null);
  const [playerCalls, setPlayerCalls] = useState([]);
  const [playerStatuses, setPlayerStatuses] = useState([]);
  const [gameMinutes, setGameMinutes] = useState([]);
  const [gameEvents, setGameEvents] = useState([]);
  const [gameResults, setGameResults] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [hasMinutesRecords, setHasMinutesRecords] = useState(false);
  const [hasPlayerStatuses, setHasPlayerStatuses] = useState(false);
  const [hasGameEventsRecords, setHasGameEventsRecords] = useState(false);
  const [hasGameRatings, setHasGameRatings] = useState(false);
  const [gameRatings, setGameRatings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('game')
          .select()
          .eq('id', gameId)
          .single();

        if (error) {
          throw error;
        }

        setGameDetails(data);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch game details: ' + (error.message || 'Unknown error'));
        setGameDetails(null);
      }
    };

    const fetchPlayerCalls = async () => {
      try {
        const { data, error } = await supabase
          .from('call_players_game')
          .select(`
            player_id,
            call,
            players (Name)
          `)
          .eq('game_id', gameId);

        if (error) {
          throw error;
        }

        setPlayerCalls(data || []);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch player calls: ' + (error.message || 'Unknown error'));
        setPlayerCalls([]);
      }
    };

    const fetchGameMinutes = async () => {
      try {
        const { data, error } = await supabase
          .from('game_minutes')
          .select(`
            player_id,
            minutes,
            players (Name)
          `)
          .eq('game_id', gameId);

        if (error) {
          throw error;
        }

        setGameMinutes(data || []);
        setHasMinutesRecords(data.length > 0);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch game minutes: ' + (error.message || 'Unknown error'));
        setGameMinutes([]);
      }
    };

    const fetchGameEvents = async () => {
      try {
        const { data, error } = await supabase
          .from('game_event')
          .select(`
            player_id,
            minute,
            type,
            players (Name)
          `)
          .eq('game_id', gameId);

        if (error) {
          throw error;
        }

        setGameEvents(data || []);
        setHasGameEventsRecords(data.length > 0);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch game events: ' + (error.message || 'Unknown error'));
        setGameEvents([]);
      }
    };

    const fetchGameRatings = async () => {
      try {
        const { data, error } = await supabase
          .from('game_ratings')
          .select('player_id, rating')
          .eq('game_id', gameId);

        setGameRatings(data || []);
        setHasGameRatings(data.length > 0);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch game ratings: ' + (error.message || 'Unknown error'));
        setGameRatings([]);
      }
    };

    const fetchGameResults = async () => {
      try {
        const { data, error } = await supabase
          .from('game_results')
          .select()
          .eq('game_id', gameId);

        if (error) {
          throw error;
        }

        setGameResults(data || []);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch game results: ' + (error.message || 'Unknown error'));
        setGameResults([]);
      }
    };

    const fetchPlayerStatuses = async () => {
      try {
        const { data, error } = await supabase
          .from('status_player_game')
          .select('player_id, status, position')
          .eq('game_id', gameId);

        if (error) {
          throw error;
        }

        setPlayerStatuses(data || []);
        setHasPlayerStatuses(data.length > 0);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch player statuses: ' + (error.message || 'Unknown error'));
        setPlayerStatuses([]);
      }
    };

    fetchGameDetails();
    fetchPlayerCalls();
    fetchGameMinutes();
    fetchGameEvents();
    fetchPlayerStatuses();
    fetchGameRatings();
    fetchGameResults();
  }, [gameId]);

  const handleAddCalls = () => {
    navigate(`/add-calls?gameId=${gameId}`);
  };

  const handleInsertGameMinutes = () => {
    navigate(`/insert-game-minutes?gameId=${gameId}`);
  };

  const handleInsertGameEvents = () => {
    navigate(`/insert-game-events?gameId=${gameId}`);
  };

  const handleInsertGameRatings = () => {
    navigate(`/insert-game-ratings?gameId=${gameId}`);
  };

  const handleInsertGameResult = () => {
    navigate(`/insert-game-result/${gameId}`);
  };

  const hasCalls = playerCalls.length > 0;
  const hasResults = gameResults.length > 0;

  // Map statuses, minutes, and events by player_id for quick lookup
  const statusMap = playerStatuses.reduce((acc, status) => {
    acc[status.player_id] = status;
    return acc;
  }, {});

  const minutesMap = gameMinutes.reduce((acc, minute) => {
    acc[minute.player_id] = minute;
    return acc;
  }, {});

  const eventsMap = gameEvents.reduce((acc, event) => {
    if (!acc[event.player_id]) {
      acc[event.player_id] = [];
    }
    acc[event.player_id].push(event);
    return acc;
  }, {});

  // Get game results
  const gameResult = gameResults.length > 0 ? gameResults[0] : {};

  return (
    <div className="page game-detail">
      <button onClick={onBack} className="back-button">Back to Games</button>

      {/* Display Game Result Information */}
      {gameResult.my_team && gameResult.adversary_team && (
        <div className="game-results">
          <h2>Game Results</h2>
          <p><strong>Game:</strong> {gameResult.my_team} - {gameResult.adversary_team}</p>
        </div>
      )}

      {!hasCalls && (
        <button onClick={handleAddCalls} className="add-calls-button">Add Calls to Players</button>
      )}

      {/* Conditionally render Insert Game Minutes button */}
      {!hasMinutesRecords && (
        <button onClick={handleInsertGameMinutes} className="insert-minutes-button">
          Insert Game Minutes
        </button>
      )}

      {!hasPlayerStatuses && (
        <button onClick={() => navigate(`/manage-player-status?gameId=${gameId}`)} className="manage-status-button">
          Manage Player Statuses
        </button>
      )}

      {!hasGameEventsRecords && (
        <button onClick={handleInsertGameEvents} className="insert-game-events-button">
          Insert Game Events
        </button>
      )}

      {!hasGameRatings && (
        <button onClick={handleInsertGameRatings} className="insert-game-ratings-button">
          Insert Game Ratings
        </button>
      )}

      {!hasResults && (
        <button onClick={handleInsertGameResult} className="insert-game-result-button">
          Insert Game Result
        </button>
      )}

      {fetchError && <p className="error">{fetchError}</p>}
      {gameDetails ? (
        <div className="game-details">
          <h2>Game Details</h2>
          <p><strong>Journey:</strong> {gameDetails.Journey}</p>
          <p><strong>Date:</strong> {gameDetails.Date}</p>
          <p><strong>Time:</strong> {gameDetails.Time}</p>
          <p><strong>Weather:</strong> {gameDetails.Weather}</p>
          <p><strong>Temperature:</strong> {gameDetails.Temperature}°C</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {/* Display General Game Events */}
      {gameEvents.length > 0 && (
        <div className="game-events">
          <h3>Game Events</h3>
          <ul>
            {gameEvents.map((event, index) => (
              <li key={index}>
                {event.players?.Name || 'Unknown Player'}: {event.type} at {event.minute} minutes
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Display Player Calls */}
      <div className="player-calls">
        <h3>Player Calls</h3>
        {playerCalls.length === 0 ? (
          <p>No calls recorded for this game.</p>
        ) : (
          <div className="player-calls-list">
            {playerCalls.map(({ player_id, call, players }) => {
              // Fetch corresponding status and minutes for the current player_id
              const status = statusMap[player_id] || {};
              const minutes = minutesMap[player_id] || { minutes: 'Not recorded' };

              return (
                <div key={player_id} className="player-call-item">
                  <p className="player-name">{players?.Name || 'Unknown'}</p>
                  <p className="player-call">Call: {call || 'No call recorded'}</p>
                  <p className="player-status">Status: {status.status || 'No status recorded'}</p>
                  <p className="player-position">Position: {status.position || 'No position recorded'}</p>
                  <p className="player-minutes">Minutes Played: {minutes.minutes || 'Not recorded'}</p>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default GameDetailPage;
