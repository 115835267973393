// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [userGroups, setUserGroups] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchProfileData = async () => {
        console.log('Fetching profile data...'); // Log to confirm function is running

        try {
            // Fetch the current user
            const { data: { user }, error: userError } = await supabase.auth.getUser();

            if (userError) {
                console.log('User Error:', userError.message); // Log user fetching error
                throw new Error('Erro ao buscar usuário: ' + userError.message);
            }

            const userId = user.id;

            console.log('User ID:', userId); // Log the userId for debugging

            // Fetch user profile data (email and username) from profiles table
            const { data: profileData, error: profileError } = await supabase
                .from('profiles')
                .select('email, username')
                .eq('id', userId)
                .single();

            if (profileError) {
                console.log('Profile Error:', profileError.message); // Log profile fetching error
                throw new Error('Erro ao buscar informações do perfil: ' + profileError.message);
            }

            console.log('Profile Data:', profileData); // Log profile data

            setProfile(profileData);

            // Fetch user groups from user_groups and join with groups table
            const { data: userGroupsData, error: userGroupsError } = await supabase
                .from('user_groups')
                .select('group_id, role')
                .eq('user_id', userId);

            if (userGroupsError) {
                console.log('User Groups Error:', userGroupsError.message); // Log user group fetching error
                throw new Error('Erro ao buscar grupos do usuário: ' + userGroupsError.message);
            }

            console.log('User Groups Data:', userGroupsData); // Log user group data

            // Fetch group names based on group_id from user_groups
            const userGroupDetails = await Promise.all(
                userGroupsData.map(async (userGroup) => {
                    const { data: groupData, error: groupError } = await supabase
                        .from('groups')
                        .select('name')
                        .eq('id', userGroup.group_id)
                        .single();

                    if (groupError) {
                        console.error('Group Error:', groupError.message); // Log error in fetching group name
                        return { ...userGroup, groupName: 'Desconhecido' };
                    }

                    return {
                        ...userGroup,
                        groupName: groupData.name,
                    };
                })
            );

            console.log('User Group Details:', userGroupDetails); // Log user group details with group names

            setUserGroups(userGroupDetails);
        } catch (err) {
            console.log('Error:', err.message); // Log any caught errors
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch profile data when the component mounts
    useEffect(() => {
        fetchProfileData();
    }, []);

    if (loading) {
        console.log('Loading...'); // Log loading state
        return <div>Carregando...</div>; // Loading state
    }

    if (error) {
        console.log('Error state:', error); // Log if error state is hit
        return <div>{error}</div>; // Display error message
    }

    console.log('Rendering profile and user groups...'); // Log just before rendering the UI

    return (
        <div>
            <h1>Perfil</h1>
            {profile ? (
                <div>
                    <h2>Informações do Usuário</h2>
                    <p><strong>Email:</strong> {profile.email}</p>
                    <p><strong>Nome de Usuário:</strong> {profile.username}</p>
                </div>
            ) : (
                <p>Perfil não encontrado.</p>
            )}

            <h2>Grupos</h2>
            {userGroups.length > 0 ? (
                <ul>
                    {userGroups.map((userGroup, index) => (
                        <li key={index}>
                            <p><strong>Grupo:</strong> {userGroup.groupName}</p>
                            <p><strong>Função:</strong> {userGroup.role}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Você não pertence a nenhum grupo.</p>
            )}
        </div>
    );
};

export default Profile;

