/*
import React, { useState } from "react";
import supabase from '../config/supabaseClient';


const SignUpWithEmail = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.signInWithOtp({ email });

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage("Check your email for the magic link!");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "50px" }}>
      <h2>Sign Up with Email</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "10px", width: "100%" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <button type="submit" style={{ padding: "10px", width: "100%" }}>
            Send Magic Link
          </button>
        </div>
      </form>
      {message && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default SignUpWithEmail;
*/

/*
import React, { useState } from "react";
import supabase from '../config/supabaseClient';

const SignUpWithEmail = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();

    const defaultPassword = "DefaultPassword123"; // You can change this password

    const { error } = await supabase.auth.signUp({
      email,
      password: defaultPassword,
    });

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage("Account created! Please check your email to confirm your account.");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "50px" }}>
      <h2>Sign Up with Email</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "10px", width: "100%" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <button type="submit" style={{ padding: "10px", width: "100%" }}>
            Create Account
          </button>
        </div>
      </form>
      {message && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default SignUpWithEmail;
*/

import React, { useState } from "react";
import supabase from '../config/supabaseClient';

const SignUpWithEmail = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();

    const defaultPassword = "DefaultPassword123"; // You can change this password

    // Step 1: Create the user account
    const { error: signUpError } = await supabase.auth.signUp({
      email,
      password: defaultPassword,
      options: { emailRedirectTo: null }, // Prevent email confirmation
    });

    if (signUpError) {
      setMessage(`Error creating account: ${signUpError.message}`);
      return;
    }

    // Step 2: Send the magic link
    //const { error: otpError } = await supabase.auth.signInWithOtp({ email });

    const { error: otpError } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: 'https://demo.footbased.uk/setup-password' // Ensure full URL with path
      }
    });

    if (otpError) {
      setMessage(`Error sending magic link: ${otpError.message}`);
    } else {
      setMessage("Account created! Check your email for the magic link to sign in.");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "50px" }}>
      <h2>Sign Up with Email</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "10px", width: "100%" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <button type="submit" style={{ padding: "10px", width: "100%" }}>
            Create Account
          </button>
        </div>
      </form>
      {message && (
        <div style={{ marginTop: "20px", color: message.includes("Error") ? "red" : "green" }}>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default SignUpWithEmail;
