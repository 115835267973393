import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const Create_Internal_Scouting = () => {
  const navigate = useNavigate();

  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Date, setDate] = useState('');
  const [Birth_date, setBirth_date] = useState('');
  const [Club, setClub] = useState('');
  const [Position, setPosition] = useState('');
  const [Telephone_number, setTelephone_number] = useState('');
  const [Grade, setGrade] = useState('');
  const [coment, setcoment] = useState('');
  const [formError, setFormError] = useState(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if ( !Name || !Number || !Date || !Birth_date || !Club || !Position || !Telephone_number || !Grade || !coment ) {
      setFormError('Please fill in all required fields.');
      return;
    }

    // Use selectedPlayerId if type is 'player'
    const playerIdNumber =  selectedPlayerId;

    try {
      const { data, error } = await supabase
        .from('external_scouting') // Ensure this matches your actual table name
        .insert([{
          Name,
          Number,
          Date,
          Birth_date,
          Club,
          Position,
          Telephone_number,
          Grade,
          coment
        }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/scouting', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>

          <div className="form-group">
            <label htmlFor="Name">Name:</label>
            <input
              type="text"
              id="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Number">Number:</label>
            <input
              type="text"
              id="Number"
              value={Number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Birth_date">Birth_date:</label>
            <input
              type="date"
              id="Birth_date"
              value={Birth_date}
              onChange={(e) => setBirth_date(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Club">Club:</label>
            <input
              type="text"
              id="Club"
              value={Club}
              onChange={(e) => setClub(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Telephone_number">Telephone_number:</label>
            <input
              type="text"
              id="Telephone_number"
              value={Telephone_number}
              onChange={(e) => setTelephone_number(e.target.value)}
            />
          </div>


          <div className="form-group">
            <label htmlFor="Position">Position:</label>
            <input
              type="text"
              id="Position"
              value={Position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>

          <div className="form-group">
  <label htmlFor="Grade">Grade:</label>
  <input
    type="range"
    min="1"
    max="10"
    id="Grade"
    value={Grade}
    onChange={(e) => setGrade(e.target.value)}
  />
  <span>{Grade}</span> {/* This will display the current value of the slider */}
</div>

        <div className="form-group">
          <label htmlFor="Date">Date:</label>
          <input
            type="date"
            id="Date"
            value={Date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="coment">Coment:</label>
          <textarea
            id="coment"
            value={coment}
            onChange={(e) => setcoment(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button type="submit">Submit</button>
        </div>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create_Internal_Scouting;
