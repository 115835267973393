import React from 'react';

const Querie_practice = ({ item }) => {
  return (
    <div className="smoothie-card">
      <h3>{item.journey}</h3>
      <p>{item.date}</p>
      <p>{item.time}</p>
      <p>{item.weather}</p>
    </div>
  );
};

export default Querie_practice;
