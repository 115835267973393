// src/pages/ManageUsersAndGroups.js
import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';

const AddUserToGroup = () => {
    // Estado para adicionar usuários a grupos
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [userGroups, setUserGroups] = useState([]); // State for user groups data with emails, usernames, and group names
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [userError, setUserError] = useState('');
    const [userSuccess, setUserSuccess] = useState('');

    // Estado para adicionar novos grupos
    const [groupName, setGroupName] = useState('');
    const [groupError, setGroupError] = useState('');
    const [groupSuccess, setGroupSuccess] = useState('');

    // Funções para buscar dados
    const fetchUsers = async () => {
        const { data, error } = await supabase.from('profiles').select('id, email, username');
        if (error) {
            console.error('Erro ao buscar usuários:', error.message);
        } else {
            setUsers(data);
        }
    };

    const fetchGroups = async () => {
        const { data, error } = await supabase.from('groups').select('id, name');
        if (error) {
            console.error('Erro ao buscar grupos:', error.message);
        } else {
            setGroups(data);
        }
    };

    const fetchUserGroups = async () => {
        const { data, error } = await supabase
            .from('user_groups')
            .select('user_id, group_id')
            .eq('role', 'member'); // Optionally filter by role

        if (error) {
            console.error('Erro ao buscar grupos de usuários:', error.message);
        } else {
            // Fetch associated emails, usernames for user_ids, and group names for group_ids
            const userGroupDetails = await Promise.all(
                data.map(async (userGroup) => {
                    // Fetch user email and username
                    const { data: userProfile, error: userError } = await supabase
                        .from('profiles')
                        .select('email, username')
                        .eq('id', userGroup.user_id)
                        .single();

                    // Fetch group name
                    const { data: groupData, error: groupError } = await supabase
                        .from('groups')
                        .select('name')
                        .eq('id', userGroup.group_id)
                        .single();

                    if (userError || groupError) {
                        console.error('Erro ao buscar detalhes:', userError?.message || groupError?.message);
                        return {
                            ...userGroup,
                            email: 'Desconhecido',
                            username: 'Desconhecido',
                            groupName: 'Desconhecido'
                        };
                    } else {
                        return {
                            ...userGroup,
                            email: userProfile.email,
                            username: userProfile.username,
                            groupName: groupData.name
                        };
                    }
                })
            );
            setUserGroups(userGroupDetails);
        }
    };

    // Função para adicionar usuário a grupo
    const handleAddUserToGroup = async () => {
        if (!selectedUser || !selectedGroup) {
            setUserError('Por favor, selecione um usuário e um grupo.');
            return;
        }

        const { error } = await supabase
            .from('user_groups')
            .insert([{ user_id: selectedUser, group_id: selectedGroup, role: 'member' }]);

        if (error) {
            console.error('Erro ao adicionar usuário ao grupo:', error.message);
            setUserError('Erro ao adicionar usuário ao grupo.');
            setUserSuccess('');
        } else {
            setUserSuccess('Usuário adicionado ao grupo com sucesso!');
            setUserError('');
            setSelectedUser('');
            setSelectedGroup('');
            fetchUserGroups(); // Refresh user groups after adding a new one
        }
    };

    // Função para adicionar novo grupo
    const handleAddGroup = async () => {
        if (!groupName) {
            setGroupError('Por favor, insira um nome para o grupo.');
            return;
        }

        const { data, error } = await supabase
            .from('groups')
            .insert([{ name: groupName }]);

        if (error) {
            console.error('Erro ao adicionar grupo:', error.message);
            setGroupError('Erro ao adicionar grupo.');
            setGroupSuccess('');
        } else {
            setGroupSuccess('Grupo adicionado com sucesso!');
            setGroupError('');
            setGroupName('');
            fetchGroups(); // Atualiza a lista de grupos
        }
    };

    // Função para deletar um usuário de um grupo
    const handleDeleteUserFromGroup = async (userId, groupId) => {
        const { error } = await supabase
            .from('user_groups')
            .delete()
            .eq('user_id', userId)
            .eq('group_id', groupId);

        if (error) {
            console.error('Erro ao remover usuário do grupo:', error.message);
        } else {
            fetchUserGroups(); // Atualiza a lista de grupos após a remoção
        }
    };

    // Efeito para buscar dados ao montar o componente
    useEffect(() => {
        fetchUsers();
        fetchGroups();
        fetchUserGroups(); // Fetch user group data when the component loads
    }, []);

    return (
        <div>
            <h1>Gerenciar Usuários e Grupos</h1>

            {/* Adicionar Usuário ao Grupo */}
            <div>
                <h2>Adicionar Usuário ao Grupo</h2>
                {userError && <p style={{ color: 'red' }}>{userError}</p>}
                {userSuccess && <p style={{ color: 'green' }}>{userSuccess}</p>}
                <div>
                    <label htmlFor="user">Usuário:</label>
                    <select
                        id="user"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="">Selecione um usuário</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.email}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="group">Grupo:</label>
                    <select
                        id="group"
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                    >
                        <option value="">Selecione um grupo</option>
                        {groups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button onClick={handleAddUserToGroup}>Adicionar ao Grupo</button>
            </div>

            {/* Adicionar Novo Grupo */}
            <div>
                <h2>Adicionar Novo Grupo</h2>
                {groupError && <p style={{ color: 'red' }}>{groupError}</p>}
                {groupSuccess && <p style={{ color: 'green' }}>{groupSuccess}</p>}
                <div>
                    <label htmlFor="groupName">Nome do Grupo:</label>
                    <input
                        type="text"
                        id="groupName"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </div>
                <button onClick={handleAddGroup}>Adicionar Grupo</button>
            </div>

            {/* Listar Usuários em Grupos */}
            <div>
                <h2>Usuários em Grupos</h2>
                {userGroups.length === 0 ? (
                    <p>Nenhum usuário foi adicionado a um grupo ainda.</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Email do Usuário</th>
                                <th>Nome de Usuário</th>
                                <th>Nome do Grupo</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userGroups.map((userGroup, index) => (
                                <tr key={index}>
                                    <td>{userGroup.email}</td>
                                    <td>{userGroup.username}</td>
                                    <td>{userGroup.groupName}</td>
                                    <td>
                                        <button
                                            onClick={() => handleDeleteUserFromGroup(userGroup.user_id, userGroup.group_id)}
                                        >
                                            Remover
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default AddUserToGroup;
