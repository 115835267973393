import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import Querie_report from '../components/Querie_report3';
import '../index.css'; // Adjust the path according to your project structure
import '../querie_report.css';

function Filter_test() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [nameValue, setNameValue] = useState('');
  const [testNameValue, setTestNameValue] = useState('');
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [testNameSuggestions, setTestNameSuggestions] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedTestName, setSelectedTestName] = useState('');

  // Fetch matching names for autocomplete
  useEffect(() => {
    const fetchNameSuggestions = async () => {
      if (nameValue.trim() === '') {
        setNameSuggestions([]);
        return;
      }

      const { data: results, error } = await supabase
        .from('players')
        .select('Name')
        .ilike('Name', `%${nameValue}%`);

      if (error) {
        console.error(error);
        setError(error);
      } else {
        setNameSuggestions(results || []);
      }
    };

    fetchNameSuggestions();
  }, [nameValue]);

  // Fetch matching test names for autocomplete
  useEffect(() => {
    const fetchTestNameSuggestions = async () => {
      if (testNameValue.trim() === '') {
        setTestNameSuggestions([]);
        return;
      }

      const { data: results, error } = await supabase
        .from('tests')
        .select('test_name')
        .ilike('test_name', `%${testNameValue}%`);

      if (error) {
        console.error(error);
        setError(error);
      } else {
        setTestNameSuggestions(results || []);
      }
    };

    fetchTestNameSuggestions();
  }, [testNameValue]);

  // Fetch data based on selected name and test name
  useEffect(() => {
    const fetchData = async () => {
      if (selectedName.trim() === '' || selectedTestName.trim() === '') {
        setData([]);
        return;
      }

      // Fetch the player ID based on the selected name
      const { data: playerResults, error: playerError } = await supabase
        .from('players')
        .select('id')
        .eq('Name', selectedName)
        .single();

      if (playerError) {
        console.error(playerError);
        setError(playerError);
        return;
      }

      const playerId = playerResults?.id;

      // Fetch the data using the player ID and selected test name
      const { data: results, error: testError } = await supabase.rpc('get_test_record', {
        p_player_name: selectedName,  // Ensure this matches the parameter type
        p_test_name: selectedTestName
      });
      

      if (testError) {
        console.error(testError);
        setError(testError);
      } else {
        setData(results);
      }
    };

    fetchData();
  }, [selectedName, selectedTestName]);

  const handleNameInputChange = (e) => {
    setNameValue(e.target.value);
  };

  const handleTestNameInputChange = (e) => {
    setTestNameValue(e.target.value);
  };

  const handleNameSuggestionClick = (name) => {
    setSelectedName(name);
    setNameValue(name); // Optional: update input value with selected name
    setNameSuggestions([]); // Clear suggestions after selection
  };

  const handleTestNameSuggestionClick = (testName) => {
    setSelectedTestName(testName);
    setTestNameValue(testName); // Optional: update input value with selected test name
    setTestNameSuggestions([]); // Clear suggestions after selection
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="page">
      <h2>Filter Report</h2>
      <div className="input-group">
        <label htmlFor="nameInput">Player Name:</label>
        <input
          id="nameInput"
          type="text"
          value={nameValue}
          onChange={handleNameInputChange}
          placeholder="Enter Player Name"
        />
        {nameSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {nameSuggestions.map((suggestion) => (
              <li key={suggestion.Name} onClick={() => handleNameSuggestionClick(suggestion.Name)}>
                {suggestion.Name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="testNameInput">Test Name:</label>
        <input
          id="testNameInput"
          type="text"
          value={testNameValue}
          onChange={handleTestNameInputChange}
          placeholder="Enter Test Name"
        />
        {testNameSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {testNameSuggestions.map((suggestion) => (
              <li key={suggestion.test_name} onClick={() => handleTestNameSuggestionClick(suggestion.test_name)}>
                {suggestion.test_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="querie-grid">
        {data.length > 0 ? (
          data.map((item, index) => (
            <Querie_report key={index} item={item} />
          ))
        ) : (
          <p>No reports found</p>
        )}
      </div>
    </div>
  );
}

export default Filter_test;
