import { useState } from "react";
import supabase from "../config/supabaseClient";

const UploadExercise = () => {
  const [file, setFile] = useState(null); // State for the file
  const [playersNumber, setPlayersNumber] = useState('');
  const [material, setMaterial] = useState('');
  const [resume, setResume] = useState('');
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadURL, setUploadURL] = useState('');

  // Handle file change
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  // Function to sanitize and generate a unique file name
  const generateUniqueFileName = (fileName) => {
    const sanitizedFileName = fileName
      .toLowerCase()                   // Convert to lowercase
      .replace(/\s+/g, '_')            // Replace spaces with underscores
      .replace(/[^\w.-]/g, '');        // Remove non-alphanumeric characters except dots and hyphens

    const timestamp = Date.now(); // Generate a timestamp
    const uniqueFileName = `${timestamp}_${sanitizedFileName}`; // Add timestamp to the file name

    return uniqueFileName;
  };

  // Upload file to Supabase Storage and save data to the Exercises table
  const handleUpload = async () => {
    if (!file || !playersNumber || !material || !resume || !description) {
      setError('Please fill in all fields and select a file to upload.');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Generate a unique file name
      const uniqueFileName = generateUniqueFileName(file.name);

      // Upload the file to Supabase Storage
      const { data, error: uploadError } = await supabase
        .storage
        .from('exercises') // Your bucket name
        .upload(uniqueFileName, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get the public URL of the uploaded file
      const { data: publicData, error: urlError } = supabase
        .storage
        .from('exercises')
        .getPublicUrl(uniqueFileName);

      if (urlError) {
        throw urlError;
      }

      const publicUrl = publicData.publicUrl;

      // Ensure the public URL is not undefined
      if (!publicUrl) {
        throw new Error('Failed to get public URL.');
      }

      setUploadURL(publicUrl);

      // Insert the file URL and other form data into the Exercises table
      const { error: insertError } = await supabase
        .from('Exercises') // Your table name
        .insert([{ 
          players_number: playersNumber, 
          material: material, 
          resume: resume, 
          description: description, 
          file_url: publicUrl 
        }]);

      if (insertError) {
        throw insertError;
      }

      setUploading(false);
    } catch (err) {
      setError('An error occurred: ' + (err.message || 'Unknown error'));
      setUploading(false);
    }
  };

  return (
    <div className="page upload-exercise">
      <h1>Upload Exercise</h1>
      <form>
        <label>
          Players Number:
          <input
            type="number"
            value={playersNumber}
            onChange={(e) => setPlayersNumber(e.target.value)}
            required
          />
        </label>
        <label>
          Material:
          <input
            type="text"
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            required
          />
        </label>
        <label>
          Resume:
          <textarea
            value={resume}
            onChange={(e) => setResume(e.target.value)}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          File:
          <input
            type="file"
            onChange={handleFileChange}
            required
          />
        </label>
        <button type="button" onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Exercise'}
        </button>
      </form>

      {uploadURL && (
        <div>
          <p>File uploaded successfully! URL: <a href={uploadURL}>{uploadURL}</a></p>
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default UploadExercise;
