/*
import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import SmoothieCard from '../components/Querie'; // Adjust import as necessary
import '../index.css'; // Adjust the path according to your project structure

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(null, args), delay);
  };
};

function Filter2() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [nameValue, setNameValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Debounced fetch function
  const fetchSuggestions = debounce(async (value) => {
    if (value.trim() === "") {
      setSuggestions([]);
      return;
    }

    const { data: results, error } = await supabase
      .from('players') // Adjust the table name as needed
      .select('*')
      .ilike('Name', `%${value}%`); // Case-insensitive partial match on column 'Name'

    if (error) {
      console.error(error);
      setError(error);
    } else {
      setSuggestions(results);
    }
  }, 300); // Adjust debounce delay as needed

  useEffect(() => {
    fetchSuggestions(nameValue);
  }, [nameValue]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setNameValue(newValue);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="page">
      <h2>Insert the Name</h2>
      <input
        type="text"
        value={nameValue}
        onChange={handleInputChange}
        placeholder="Enter Name value"
      />
      {nameValue && (
        <ul className="suggestions-list">
          {suggestions.length > 0 ? (
            suggestions.map((item, index) => (
              <li key={index}>
                <SmoothieCard item={item} />
              </li>
            ))
          ) : (
            <p>No suggestions available</p>
          )}
        </ul>
      )}
    </div>
  );
}

export default Filter2;
*/

// src/components/Filter2.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import supabase from '../config/supabaseClient';
import SmoothieCard from '../components/Querie';
import '../index.css';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(null, args), delay);
  };
};

function Filter2() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [nameValue, setNameValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchSuggestions = debounce(async (value) => {
    if (value.trim() === "") {
      setSuggestions([]);
      return;
    }

    const { data: results, error } = await supabase
      .from('players')
      .select('*')
      .ilike('Name', `%${value}%`); // Fixed syntax for partial match

    if (error) {
      console.error(error);
      setError(error);
    } else {
      setSuggestions(results);
    }
  }, 300);

  useEffect(() => {
    fetchSuggestions(nameValue);
  }, [nameValue]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setNameValue(newValue);
  };

  const handleButtonClick = (id) => {
    navigate(`/player/${id}`); // Navigate to the player details page with the player's ID
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="page">
      <h2>Insert the Name</h2>
      <input
        type="text"
        value={nameValue}
        onChange={handleInputChange}
        placeholder="Enter Name value"
      />
      {nameValue && (
        <ul className="suggestions-list">
          {suggestions.length > 0 ? (
            suggestions.map((item) => (
              <li key={item.id}>
                <SmoothieCard item={item} />
                <button onClick={() => handleButtonClick(item.id)}>View Details</button>
              </li>
            ))
          ) : (
            <p>No suggestions available</p>
          )}
        </ul>
      )}
    </div>
  );
}

export default Filter2;
