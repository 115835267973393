import React from 'react';

const Querie_report = ({ item, players, tests }) => {
  // Find the player object based on the player_id
  const player = players.find(player => player.id === item.player_id);
  
  // Find the test object based on the test_id
  const test = tests.find(test => test.id === item.test_id);

  return (
    <div className="smoothie-card">
      <h3>Date:</h3>
      <p>{item.date}</p>
      <h3>Value:</h3>
      <p>{item.value}</p>
      <h3>Player:</h3>
      <p>{player ? player.Name : 'Player not found'}</p> {/* Show player's name */}
      <h3>Test:</h3>
      <p>{test ? test.test_name : 'Test not found'}</p> {/* Show test's name */}
    </div>
  );
};

export default Querie_report;
