import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const InsertGameResultPage = () => {
  const { gameId } = useParams(); // Extract gameId from URL
  const [myTeam, setMyTeam] = useState('');
  const [adversaryTeam, setAdversaryTeam] = useState('');
  const [points, setPoints] = useState(0);
  const [competition, setCompetition] = useState('league'); // default value
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Inserting game result:', { gameId, myTeam, adversaryTeam, points, competition });

    try {
      // Perform the insertion
      const { error } = await supabase
        .from('game_results')
        .insert([
          {
            game_id: gameId,
            my_team: myTeam,
            adversary_team: adversaryTeam,
            points: points,
            competition: competition
          }
        ]);

      if (error) {
        throw error;
      }

      // Navigate to the game detail page
      navigate(`/game-detail/${gameId}`);
    } catch (error) {
      console.error('Error inserting game result:', error);
      setError('Error inserting game result: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="page insert-game-result">
      <h2>Insert Game Result</h2>
      <form onSubmit={handleSubmit}>
        <label>
          My Team:
          <input
            type="text"
            value={myTeam}
            onChange={(e) => setMyTeam(e.target.value)}
            required
          />
        </label>
        <label>
          Adversary Team:
          <input
            type="text"
            value={adversaryTeam}
            onChange={(e) => setAdversaryTeam(e.target.value)}
            required
          />
        </label>
        <label>
          Points:
          <input
            type="number"
            value={points}
            onChange={(e) => setPoints(parseInt(e.target.value, 10))}
            required
          />
        </label>
        <label>
          Competition:
          <select
            value={competition}
            onChange={(e) => setCompetition(e.target.value)}
            required
          >
            <option value="league">League</option>
            <option value="cup">Cup</option>
            <option value="friendly">Friendly</option>
          </select>
        </label>
        <button type="submit">Submit</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default InsertGameResultPage;
