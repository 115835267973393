import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import your CSS file if you have one

const Medical = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Medical</h1>
        <p>Select a page to visit:</p>
      </header>
      <main className="home-content">
        <nav className="home-nav">
          <ul>
            <li>
              <Link to="/name_report">View Medical Reports</Link>
            </li>
            <li>
              <Link to="/Create_record">Create New Medical Report</Link>
            </li>
            <li>
              <Link to="/Filter_report">Filter Medical Report by Player Name</Link>
            </li>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/">Log-out</Link>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

export default Medical;
