import React from 'react';

const Querie_report2 = ({ item }) => {
  return (
    <div className="smoothie-card">
      <h3>{item.player_name}</h3>
      <p>{item.medical_report}</p>
      <p>{item.created_at}</p>
    </div>
  );
};

export default Querie_report2;
