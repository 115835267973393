import { useState } from "react";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const UploadImage = () => {
  const [image, setImage] = useState(null); // State for the image file
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadURL, setUploadURL] = useState('');

  // Handle image file change
  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  // Function to sanitize and generate a unique file name
  const generateUniqueFileName = (fileName) => {
    const sanitizedFileName = fileName
      .toLowerCase()                   // Convert to lowercase
      .replace(/\s+/g, '_')            // Replace spaces with underscores
      .replace(/[^\w.-]/g, '');        // Remove non-alphanumeric characters except dots and hyphens

    const timestamp = Date.now(); // Generate a timestamp
    const uniqueFileName = `${timestamp}_${sanitizedFileName}`; // Add timestamp to the file name

    return uniqueFileName;
  };

  // Upload image to Supabase Storage and save URL to the images table
  const handleUpload = async () => {
    if (!image) {
      setError('Please select an image to upload.');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Generate a unique file name
      const uniqueFileName = generateUniqueFileName(image.name);

      // Upload the image file to Supabase Storage
      const { data, error: uploadError } = await supabase
        .storage
        .from('teste') // Your bucket name
        .upload(uniqueFileName, image);

      if (uploadError) {
        throw uploadError;
      }

      // Get the public URL of the uploaded image
      const { data: publicData, error: urlError } = supabase
        .storage
        .from('teste')
        .getPublicUrl(uniqueFileName);

      if (urlError) {
        throw urlError;
      }

      const publicUrl = publicData.publicUrl;

      // Ensure the public URL is not undefined
      if (!publicUrl) {
        throw new Error('Failed to get public URL.');
      }

      setUploadURL(publicUrl);

      // Insert the public URL into the images table
      const { error: insertError } = await supabase
        .from('images') // Your table name
        .insert([{ file_url: publicUrl }]);

      if (insertError) {
        throw insertError;
      }

      setUploading(false);
    } catch (err) {
      setError('An error occurred: ' + (err.message || 'Unknown error'));
      setUploading(false);
    }
  };

  return (
    <div className="page upload-image">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload Image'}
      </button>

      {uploadURL && (
        <div>
          <p>Image uploaded successfully!</p>
          <img src={uploadURL} alt="Uploaded" style={{ width: '100%', maxWidth: '400px' }} />
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default UploadImage;
