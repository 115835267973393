/*
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const Create = () => {
  const navigate = useNavigate();

  const [Name, setName] = useState('');
  const [Birth_date, setBirth_date] = useState('');
  const [Number, setNumber] = useState('');
  const [Phone_Number, setPhone_Number] = useState('');
  const [Zone, setZone] = useState('');
  const [Position, setPosition] = useState('');
  const [formError, setFormError] = useState(null);

const handleSubmit = async (e) => {
  e.preventDefault();

  if ( !Name || !Birth_date || !Number || !Phone_Number || !Zone || !Position) {
    setFormError('Please fill in all the fields correctly.');
    return;
  }

  try {
    const { data, error } = await supabase
      .from('players')  // Ensure this matches your actual table name
      .insert([{ Name, Birth_date, Number, Phone_Number, Zone, Position }]);

    if (error) {
      console.error('Supabase error:', error);
      setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
    } else {
      console.log('Insert successful:', data);
      setFormError(null);
      navigate('/players', { replace: true });
    }
  } catch (err) {
    console.error('Unexpected error:', err);
    setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
  }
};


  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        
        <label htmlFor="Name">Name:</label>
        <input 
          type="text"
          id="Name"
          value={Name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="Birth_date">Birth Date:</label>
        <input 
          type="date"
          id="Birth_date"
          value={Birth_date}
          onChange={(e) => setBirth_date(e.target.value)}
        />

        <label htmlFor="Number">Number:</label>
        <input 
          type="number"
          id="Number"
          value={Number}
          onChange={(e) => setNumber(e.target.value)}
        />

        <label htmlFor="Phone_Number">Phone Number:</label>
        <input 
          type="number"
          id="Phone_Number"
          value={Phone_Number}
          onChange={(e) => setPhone_Number(e.target.value)}
        />

        <label htmlFor="Zone">Zone:</label>
        <input 
          type="text"
          id="Zone"
          value={Zone}
          onChange={(e) => setZone(e.target.value)}
        />

        <label htmlFor="Position">Position:</label>
        <input 
          type="text"
          id="Position"
          value={Position}
          onChange={(e) => setPosition(e.target.value)}
        />

        <button>Create Player</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create;
*/
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const Create = () => {
  const navigate = useNavigate();

  const [Name, setName] = useState('');
  const [Birth_date, setBirth_date] = useState('');
  const [Number, setNumber] = useState('');
  const [Phone_Number, setPhone_Number] = useState('');
  const [Zone, setZone] = useState('');
  const [Position, setPosition] = useState('');
  const [group_id, setGroup_id] = useState('');
  const [formError, setFormError] = useState(null);
  const [groups, setGroups] = useState([]);

  // Fetch groups from the database based on user_groups
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        // Fetch group_ids from user_groups
        const { data: userGroups, error: userGroupsError } = await supabase
          .from('user_groups') // Ensure this matches your actual table name
          .select('group_id');

        if (userGroupsError) {
          console.error('Error fetching user groups:', userGroupsError);
          return;
        }

        const groupIds = userGroups.map(userGroup => userGroup.group_id);

        // Fetch groups based on the retrieved group_ids
        const { data: groupsData, error: groupsError } = await supabase
          .from('groups') // Ensure this matches your actual table name
          .select('id, name')
          .in('id', groupIds); // Use the .in() method to filter by group_ids

        if (groupsError) {
          console.error('Error fetching groups:', groupsError);
        } else {
          setGroups(groupsData);
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      }
    };

    fetchGroups();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Name || !Birth_date || !Number || !Phone_Number || !Zone || !Position || !group_id) {
      setFormError('Please fill in all the fields correctly.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('players') // Ensure this matches your actual table name
        .insert([{ Name, Birth_date, Number, Phone_Number, Zone, Position, group_id }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/players', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>

        <label htmlFor="Name">Name:</label>
        <input 
          type="text"
          id="Name"
          value={Name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="Birth_date">Birth Date:</label>
        <input 
          type="date"
          id="Birth_date"
          value={Birth_date}
          onChange={(e) => setBirth_date(e.target.value)}
        />

        <label htmlFor="Number">Number:</label>
        <input 
          type="number"
          id="Number"
          value={Number}
          onChange={(e) => setNumber(e.target.value)}
        />

        <label htmlFor="Phone_Number">Phone Number:</label>
        <input 
          type="number"
          id="Phone_Number"
          value={Phone_Number}
          onChange={(e) => setPhone_Number(e.target.value)}
        />

        <label htmlFor="Zone">Zone:</label>
        <input 
          type="text"
          id="Zone"
          value={Zone}
          onChange={(e) => setZone(e.target.value)}
        />

        <label htmlFor="Position">Position:</label>
        <input 
          type="text"
          id="Position"
          value={Position}
          onChange={(e) => setPosition(e.target.value)}
        />

        <label htmlFor="group_id">Group:</label>
        <select
          id="group_id"
          value={group_id}
          onChange={(e) => setGroup_id(e.target.value)}
        >
          <option value="">Select a group</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>

        <button>Create Player</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create;
