import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const SearchExercises = () => {
  const [exercises, setExercises] = useState([]); // All exercises fetched from Supabase
  const [filteredExercises, setFilteredExercises] = useState([]); // Filtered exercises based on search input
  const [searchTerm, setSearchTerm] = useState(''); // Search input
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const navigate = useNavigate();

  // Fetch exercises from Supabase
  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises')
          .select('id, file_url, resume'); // Fetch the necessary columns

        if (error) {
          throw error;
        }

        setExercises(data);
        setFilteredExercises(data); // Initially, all exercises are shown
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExercises();
  }, []);

  // Handle the search input change
  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    // Filter exercises by resume that contains the search term
    const filtered = exercises.filter((exercise) =>
      exercise.resume.toLowerCase().includes(searchValue)
    );
    setFilteredExercises(filtered);
  };

  // Handle click to navigate to exercise details page
  const handleViewDetails = (id) => {
    navigate(`/exercise-details/${id}`);
  };

  return (
    <div className="page search-exercises">
      <style>{`
        .page {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        h1 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        .search-bar {
          display: block;
          margin: 0 auto 20px auto;
          padding: 10px;
          width: 100%;
          max-width: 400px;
          border: 1px solid #ddd;
          border-radius: 8px;
          font-size: 16px;
        }
        .exercises-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          margin-top: 20px;
        }
        .exercise-item {
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          padding: 15px;
          text-align: center;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        .exercise-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        }
        .exercise-image {
          max-width: 100%;
          height: auto;
          border-radius: 4px;
        }
        .exercise-resume {
          margin-top: 10px;
          font-size: 14px;
          color: #555;
        }
      `}</style>

      <h1>Search Exercises by Resume</h1>

      <input
        type="text"
        placeholder="Search by resume..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-bar"
      />

      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      <div className="exercises-container">
        {filteredExercises.length > 0 ? (
          filteredExercises.map((exercise) => (
            <div 
              key={exercise.id} 
              className="exercise-item"
              onClick={() => handleViewDetails(exercise.id)}
            >
              <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
              <p className="exercise-resume">{exercise.resume}</p>
            </div>
          ))
        ) : (
          !loading && <p>No exercises found matching "{searchTerm}".</p>
        )}
      </div>
    </div>
  );
};

export default SearchExercises;
