import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const ManagePlayerStatusPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [summonedPlayers, setSummonedPlayers] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [positions, setPositions] = useState({});
  const gameId = new URLSearchParams(location.search).get('gameId');

  useEffect(() => {
    const fetchSummonedPlayers = async () => {
      try {
        const { data, error } = await supabase
          .from('call_players_game')
          .select('player_id')
          .eq('game_id', gameId)
          .eq('call', 'summoned');

        if (error) {
          throw error;
        }

        const playerIds = data.map(record => record.player_id);
        const { data: summonedData, error: summonedError } = await supabase
          .from('players')
          .select()
          .in('id', playerIds);

        if (summonedError) {
          throw summonedError;
        }

        setSummonedPlayers(summonedData);
      } catch (error) {
        console.error('Could not fetch summoned players:', error.message || 'Unknown error');
        setSummonedPlayers([]);
      }
    };

    fetchSummonedPlayers();
  }, [gameId]);

  const handleStatusChange = (playerId, value) => {
    setStatuses(prevStatuses => ({ ...prevStatuses, [playerId]: value }));
  };

  const handlePositionChange = (playerId, value) => {
    setPositions(prevPositions => ({ ...prevPositions, [playerId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const inserts = Object.keys(statuses).map(playerId => ({
        game_id: gameId,
        player_id: parseInt(playerId),
        status: statuses[playerId],
        position: positions[playerId] || '',  // Default to empty string if no position is set
      }));

      const { error } = await supabase
        .from('status_player_game')
        .upsert(inserts);

      if (error) {
        throw error;
      }

      navigate(`/game/${gameId}`);
    } catch (error) {
      console.error('Error inserting status:', error.message || 'Unknown error');
    }
  };

  return (
    <div className="page manage-player-status">
      <h2>Manage Status and Position for Summoned Players for Game {gameId}</h2>
      <form onSubmit={handleSubmit}>
        {summonedPlayers.length === 0 ? (
          <p>No summoned players found.</p>
        ) : (
          summonedPlayers.map(player => (
            <div key={player.id} className="player-status">
              <label>
                {player.Name}:
                <select
                  value={statuses[player.id] || ''}
                  onChange={(e) => handleStatusChange(player.id, e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="first">First Team</option>
                  <option value="bench">Bench</option>
                  {/* Add more statuses as needed */}
                </select>
              </label>
              <label>
                Position:
                <input
                  type="text"
                  value={positions[player.id] || ''}
                  onChange={(e) => handlePositionChange(player.id, e.target.value)}
                />
              </label>
            </div>
          ))
        )}
        <button type="submit">Submit Statuses</button>
      </form>
    </div>
  );
};

export default ManagePlayerStatusPage;
