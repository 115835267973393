import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const Create_Test_Record = () => {
  const navigate = useNavigate();

  const [type, setType] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [playerId, setPlayerId] = useState('');
  const [testName, setTestName] = useState(''); // New state for test name
  const [testId, setTestId] = useState('');
  const [Name, setName] = useState('');
  const [date, setdate] = useState('');
  const [value, setvalue] = useState('');
  const [formError, setFormError] = useState(null);
  const [playerSuggestions, setPlayerSuggestions] = useState([]);
  const [testSuggestions, setTestSuggestions] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [selectedTestId, setSelectedTestId] = useState(null);

  useEffect(() => {
    const fetchPlayerSuggestions = async () => {
      if (playerName) {
        const { data, error } = await supabase
          .from('players')
          .select('id, Name')
          .ilike('Name', `%${playerName}%`); // Case-insensitive search

        if (error) {
          console.error('Error fetching player suggestions:', error);
          return;
        }
        setPlayerSuggestions(data || []);
      } else {
        setPlayerSuggestions([]);
      }
    };

    fetchPlayerSuggestions();
  }, [playerName]);

  useEffect(() => {
    const fetchTestSuggestions = async () => {
      if (testName) {
        const { data, error } = await supabase
          .from('tests')
          .select('id, test_name')
          .ilike('test_name', `%${testName}%`); // Case-insensitive search

        if (error) {
          console.error('Error fetching test suggestions:', error);
          return;
        }
        setTestSuggestions(data || []);
      } else {
        setTestSuggestions([]);
      }
    };

    fetchTestSuggestions();
  }, [testName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!testId || !date || !value) {
      setFormError('Please fill in all required fields.');
      return;
    }

    // Use selectedPlayerId and selectedTestId if set
    const playerIdNumber = selectedPlayerId;
    const testIdNumber = selectedTestId;

    try {
      const { data, error } = await supabase
        .from('test_record') // Ensure this matches your actual table name
        .insert([{
          player_id: playerIdNumber,
          test_id: testIdNumber,
          date: date,
          value
        }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/tests', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="playerName">Player Name:</label>
          <input
            type="text"
            id="playerName"
            value={playerName}
            onChange={(e) => {
              setPlayerName(e.target.value);
              setSelectedPlayerId(null); // Reset selected player ID on name change
            }}
          />
          <ul className="suggestions-list">
            {playerSuggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                onClick={() => {
                  setPlayerName(suggestion.Name);
                  setSelectedPlayerId(suggestion.id);
                  setPlayerId(suggestion.id);
                  setPlayerSuggestions([]); // Clear suggestions after selection
                }}
              >
                {suggestion.Name}
              </li>
            ))}
          </ul>
        </div>

        <div className="form-group">
          <label htmlFor="testName">Test Name:</label>
          <input
            type="text"
            id="testName"
            value={testName}
            onChange={(e) => {
              setTestName(e.target.value);
              setSelectedTestId(null); // Reset selected test ID on name change
            }}
          />
          <ul className="suggestions-list">
            {testSuggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                onClick={() => {
                  setTestName(suggestion.test_name);
                  setSelectedTestId(suggestion.id);
                  setTestId(suggestion.id);
                  setTestSuggestions([]); // Clear suggestions after selection
                }}
              >
                {suggestion.test_name}
              </li>
            ))}
          </ul>
        </div>

        {(type === 'parent' || type === 'coach' || type === 'director') && (
          <div className="form-group">
            <label htmlFor="Name">Name:</label>
            <input
              type="text"
              id="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="Date">Date:</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setdate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="value">Value:</label>
          <input
          typr="float"
            id="value"
            value={value}
            onChange={(e) => setvalue(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button type="submit">Submit</button>
        </div>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create_Test_Record;
