/*
import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas'; // Import the html2canvas library
import image from './image.png'; // Import the local image
import background from './bac.jpg'; // Import the background image

const TacticalBoard = () => {
  const [elements, setElements] = useState([]);
  const [draggingElement, setDraggingElement] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true); // Manage menu visibility

  const boardRef = useRef(null); // Ref for the tactical board

  // Track the count of each ball color in state
  const [colorCounts, setColorCounts] = useState({
    red: 0,
    blue: 0,
  });

  // Function to get the next available number for a given color
  const getNextNumberForColor = (color) => {
    return colorCounts[color] + 1;
  };

  // Function to update the count of a given color
  const updateColorCount = (color) => {
    setColorCounts((prevCounts) => ({
      ...prevCounts,
      [color]: prevCounts[color] + 1,
    }));
  };

  // Handle the start of dragging an element from the selection area
  const handleTouchStart = (type, src) => {
    setDraggingElement({ type, src });
    setDraggingIndex(null); // Clear any element index being dragged from the board
  };

  // Handle when the element is dropped on the tactical board
  const handleDrop = (e) => {
    e.preventDefault();
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    if (draggingElement) {
      const newElement = {
        x,
        y,
        ...draggingElement,
        number: draggingElement.type === 'ball' ? getNextNumberForColor(draggingElement.src) : null,
      };
      setElements((prevElements) => [...prevElements, newElement]);

      // Update the color count for balls
      if (draggingElement.type === 'ball') {
        updateColorCount(draggingElement.src);
      }

      setDraggingElement(null); // Reset dragging element after adding
    }

    if (draggingIndex !== null) {
      const updatedElements = elements.map((element, index) =>
        index === draggingIndex ? { ...element, x, y } : element
      );
      setElements(updatedElements);
      setDraggingIndex(null); // Reset dragging index after moving
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };

  // Handle dragging of an existing element on the board
  const handleElementTouchStart = (index) => {
    setDraggingIndex(index); // Store the index of the element being dragged
    setDraggingElement(null); // Ensure no new element is being dragged
  };

  // Toggle the visibility of the menu
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  // Capture and save the tactical board as an image with zoom-out effect
  const handleSaveBoard = () => {
    const board = boardRef.current; // Get the board reference

    html2canvas(board, { scale: 1 }).then((canvas) => {
      const width = 700; // Desired width
      const height = 500; // Desired height

      // Increased dimensions for zoom-out effect
      const zoomOutFactor = 1.2; // 20% zoom-out
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the new crop dimensions
      const cropWidth = width * zoomOutFactor;
      const cropHeight = height * zoomOutFactor;

      // Calculate the crop region
      const cropX = (canvasWidth - cropWidth) / 2;
      const cropY = (canvasHeight - cropHeight) / 2;

      // Create a new canvas to crop the original canvas
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext('2d');

      // Draw the desired portion of the original canvas onto the new canvas
      croppedCtx.drawImage(
        canvas,
        cropX, // Start x coordinate (center the crop area)
        cropY, // Start y coordinate (center the crop area)
        cropWidth, // Width of the cropped area
        cropHeight, // Height of the cropped area
        0, // Destination x coordinate
        0, // Destination y coordinate
        width, // Destination width
        height // Destination height
      );

      const link = document.createElement('a');
      link.download = 'tactical-board.png';
      link.href = croppedCanvas.toDataURL();
      link.click(); // Trigger the download
    });
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <div
        id="tactical-board" // Added an ID to target the tactical board for screenshot
        ref={boardRef} // Added ref to capture the board area
        style={{
          width: '100%',
          height: 'calc(100vh - 160px)', // Adjusted height to account for the draggable menu
          backgroundImage: `url(${background})`, // Set background image
          backgroundSize: 'contain', // Ensures the image is fully visible
          backgroundRepeat: 'no-repeat', // Prevents tiling of the image
          backgroundPosition: 'center', // Center the background image
          position: 'relative',
          border: '2px solid #000',
          margin: '20px auto',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onTouchEnd={handleDrop} // Handle touch screen drop
        onTouchMove={(e) => e.preventDefault()} // Prevent scrolling while dragging
      >
        {elements.map((element, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleElementTouchStart(index)}
            onTouchStart={() => handleElementTouchStart(index)} // Handle touch start
            style={{
              position: 'absolute',
              cursor: 'pointer',
              left: `${element.x - 20}px`, // Adjusted position for new size
              top: `${element.y - 20}px`, // Adjusted position for new size
              zIndex: 1000, // Ensure elements are above the board
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px', // Decreased size
              height: '40px', // Decreased size
            }}
          >
            {element.type === 'ball' ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  backgroundColor: element.src,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '14px', // Adjusted font size
                  color: '#fff',
                  fontWeight: 'bold',
                }}
              >
                {element.number} {}
              </div>
            ) : (
              <img
                src={element.src}
                alt="tactical element"
                style={{
                  width: '40px', // Decreased size
                  height: '40px', // Decreased size
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                }}
              />
            )}
          </div>
        ))}
      </div>

      <div
        style={{
          width: '100%',
          height: '160px', // Height of the drag menu area
          backgroundColor: '#f0f0f0',
          borderTop: '2px solid #000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px', // Padding to ensure content is not too close to edges
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          bottom: '0',
          left: '0',
          transform: menuVisible ? 'translateY(0)' : 'translateY(100%)', // Slide in/out effect
          transition: 'transform 0.3s ease-in-out', // Smooth transition
          zIndex: 1000,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{ marginRight: '10px', fontSize: '16px', color: '#333' }}>
            Drag an Element
          </h3>
          <button
            onClick={toggleMenu}
            style={{
              backgroundColor: '#ff0000',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              padding: '10px 15px', // Increased size for touch screens
              cursor: 'pointer',
              marginLeft: '10px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              fontSize: '16px',
            }}
          >
            {menuVisible ? 'Hide' : 'Show'}
          </button>
        </div>
        <div
          draggable
          onDragStart={() => handleTouchStart('ball', 'red')} // Handle drag start
          onTouchStart={() => handleTouchStart('ball', 'red')} // Handle touch start
          style={{
            width: '40px', // Decreased size
            height: '40px', // Decreased size
            margin: '0 10px',
            border: '2px solid #000',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: 'red',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px', // Adjusted font size
            color: '#fff',
            fontWeight: 'bold',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          {colorCounts.red + 1} {}
        </div>
        <div
          draggable
          onDragStart={() => handleTouchStart('ball', 'blue')} // Handle drag start
          onTouchStart={() => handleTouchStart('ball', 'blue')} // Handle touch start
          style={{
            width: '40px', // Decreased size
            height: '40px', // Decreased size
            margin: '0 10px',
            border: '2px solid #000',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: 'blue',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px', // Adjusted font size
            color: '#fff',
            fontWeight: 'bold',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          {colorCounts.blue + 1} {}
        </div>
        <img
          src={image}
          alt="element"
          draggable
          onDragStart={() => handleTouchStart('image', image)} // Handle drag start
          onTouchStart={() => handleTouchStart('image', image)} // Handle touch start
          style={{
            width: '40px', // Decreased size
            height: '40px', // Decreased size
            margin: '0 10px',
            cursor: 'pointer',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        />
      </div>

      <button
        onClick={handleSaveBoard}
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 15px', // Increased size for touch screens
          cursor: 'pointer',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          fontSize: '16px',
        }}
      >
        Save Board
      </button>
    </div>
  );
};

export default TacticalBoard;
*/

import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas'; // Import the html2canvas library
import image from './image.png'; // Import the local image
import background from './bac.jpg'; // Import the background image

const TacticalBoard = () => {
  const [elements, setElements] = useState([]);
  const [draggingElement, setDraggingElement] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true); // Manage menu visibility

  const boardRef = useRef(null); // Ref for the tactical board
  const dragStartX = useRef(0); // For drag calculations
  const dragStartY = useRef(0); // For drag calculations
  const touchStartX = useRef(0); // For touch calculations
  const touchStartY = useRef(0); // For touch calculations

  // Track the count of each ball color in state
  const [colorCounts, setColorCounts] = useState({
    red: 0,
    blue: 0,
  });

  // Function to get the next available number for a given color
  const getNextNumberForColor = (color) => {
    return colorCounts[color] + 1;
  };

  // Function to update the count of a given color
  const updateColorCount = (color) => {
    setColorCounts((prevCounts) => ({
      ...prevCounts,
      [color]: prevCounts[color] + 1,
    }));
  };

  // Handle the start of dragging an element from the selection area
  const handleDragStart = (type, src) => {
    setDraggingElement({ type, src });
    setDraggingIndex(null); // Clear any element index being dragged from the board
  };

  // Handle when the element is dropped on the tactical board
  const handleDrop = (e) => {
    e.preventDefault();
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    if (draggingElement) {
      const newElement = {
        x,
        y,
        ...draggingElement,
        number: draggingElement.type === 'ball' ? getNextNumberForColor(draggingElement.src) : null,
      };
      setElements((prevElements) => [...prevElements, newElement]);

      // Update the color count for balls
      if (draggingElement.type === 'ball') {
        updateColorCount(draggingElement.src);
      }

      setDraggingElement(null); // Reset dragging element after adding
    }

    if (draggingIndex !== null) {
      const updatedElements = elements.map((element, index) =>
        index === draggingIndex ? { ...element, x, y } : element
      );
      setElements(updatedElements);
      setDraggingIndex(null); // Reset dragging index after moving
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };

  // Handle dragging of an existing element on the board
  const handleElementStart = (index, e) => {
    e.preventDefault();
    setDraggingIndex(index); // Store the index of the element being dragged
    setDraggingElement(null); // Ensure no new element is being dragged
    dragStartX.current = e.clientX || (e.touches && e.touches[0].clientX);
    dragStartY.current = e.clientY || (e.touches && e.touches[0].clientY);
  };

  // Handle dragging movement
  const handleDragMove = (e) => {
    if (draggingIndex !== null) {
      const x = e.clientX || (e.touches && e.touches[0].clientX);
      const y = e.clientY || (e.touches && e.touches[0].clientY);

      const updatedElements = elements.map((element, index) =>
        index === draggingIndex ? { ...element, x: element.x + (x - dragStartX.current), y: element.y + (y - dragStartY.current) } : element
      );
      setElements(updatedElements);
      dragStartX.current = x;
      dragStartY.current = y;
    }
  };

  // Handle the end of dragging
  const handleDragEnd = () => {
    setDraggingIndex(null); // End dragging
  };

  // Handle double-click to add elements to the board
  const handleDoubleClick = (e) => {
    const boardRect = boardRef.current.getBoundingClientRect();
    const x = e.clientX - boardRect.left;
    const y = e.clientY - boardRect.top;

    if (draggingElement) {
      const newElement = {
        x,
        y,
        ...draggingElement,
        number: draggingElement.type === 'ball' ? getNextNumberForColor(draggingElement.src) : null,
      };
      setElements((prevElements) => [...prevElements, newElement]);

      // Update the color count for balls
      if (draggingElement.type === 'ball') {
        updateColorCount(draggingElement.src);
      }

      setDraggingElement(null); // Reset dragging element after adding
    }
  };

  // Add event listeners for mouse and touch events
  useEffect(() => {
    window.addEventListener('mousemove', handleDragMove);
    window.addEventListener('mouseup', handleDragEnd);
    window.addEventListener('touchmove', handleDragMove);
    window.addEventListener('touchend', handleDragEnd);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [draggingIndex, elements]);

  // Toggle the visibility of the menu
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  // Capture and save the tactical board as an image with zoom-out effect
  const handleSaveBoard = () => {
    const board = boardRef.current; // Get the board reference

    html2canvas(board, { scale: 1 }).then((canvas) => {
      const width = 700; // Desired width
      const height = 500; // Desired height

      // Increased dimensions for zoom-out effect
      const zoomOutFactor = 1.2; // 20% zoom-out
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the new crop dimensions
      const cropWidth = width * zoomOutFactor;
      const cropHeight = height * zoomOutFactor;

      // Calculate the crop region
      const cropX = (canvasWidth - cropWidth) / 2;
      const cropY = (canvasHeight - cropHeight) / 2;

      // Create a new canvas to crop the original canvas
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext('2d');

      // Draw the desired portion of the original canvas onto the new canvas
      croppedCtx.drawImage(
        canvas,
        cropX, // Start x coordinate (center the crop area)
        cropY, // Start y coordinate (center the crop area)
        cropWidth, // Width of the cropped area
        cropHeight, // Height of the cropped area
        0, // Destination x coordinate
        0, // Destination y coordinate
        width, // Destination width
        height // Destination height
      );

      const link = document.createElement('a');
      link.download = 'tactical-board.png';
      link.href = croppedCanvas.toDataURL();
      link.click(); // Trigger the download
    });
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <div
        id="tactical-board" // Added an ID to target the tactical board for screenshot
        ref={boardRef} // Added ref to capture the board area
        style={{
          width: '100%',
          height: 'calc(100vh - 160px)', // Adjusted height to account for the draggable menu
          backgroundImage: `url(${background})`, // Set background image
          backgroundSize: 'contain', // Ensures the image is fully visible
          backgroundRepeat: 'no-repeat', // Prevents tiling of the image
          backgroundPosition: 'center', // Center the background image
          position: 'relative',
          border: '2px solid #000',
          margin: '20px auto',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onTouchEnd={handleDrop} // Handle touch screen drop
        onTouchMove={(e) => e.preventDefault()} // Prevent scrolling while dragging
        onDoubleClick={handleDoubleClick} // Handle double click to add elements
      >
        {elements.map((element, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => handleElementStart(index, e)}
            onTouchStart={(e) => handleElementStart(index, e)} // Handle touch start
            style={{
              position: 'absolute',
              cursor: 'pointer',
              left: `${element.x - 25}px`,
              top: `${element.y - 25}px`,
              zIndex: 1000, // Ensure elements are above the board
            }}
          >
            {element.type === 'ball' ? (
              <div
                style={{
                  width: '40px', // Decreased size
                  height: '40px', // Decreased size
                  borderRadius: '50%',
                  backgroundColor: element.src,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                }}
              >
                {element.number && (
                  <span
                    style={{
                      fontSize: '12px', // Adjusted font size
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    {element.number}
                  </span>
                )}
              </div>
            ) : (
              <img
                src={element.src}
                alt="tactical element"
                style={{
                  width: '40px', // Decreased size
                  height: '40px', // Decreased size
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                }}
              />
            )}
          </div>
        ))}
      </div>

      {/* Persistent Menu Toggle Button */}
      <button
        onClick={toggleMenu}
        style={{
          position: 'fixed',
          top: '60px', // Adjusted to be lower from the top
          left: '20px',
          backgroundColor: '#ff0000',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 15px',
          cursor: 'pointer',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          fontSize: '16px',
          zIndex: 1000,
        }}
      >
        {menuVisible ? 'Hide Menu' : 'Show Menu'}
      </button>

      {/* Draggable Menu */}
      <div
        style={{
          width: '100%',
          height: '160px', // Height of the drag menu area
          backgroundColor: '#f0f0f0',
          borderTop: '2px solid #000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          bottom: '0',
          left: '0',
          transform: menuVisible ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease-in-out',
          zIndex: 1000,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{ marginRight: '10px', fontSize: '16px', color: '#333' }}>
            Drag or Double-Click to Add an Element
          </h3>
          <div
            draggable
            onDragStart={() => handleDragStart('ball', 'red')} // Handle drag start
            onTouchStart={() => handleDragStart('ball', 'red')} // Handle touch start
            style={{
              width: '40px',
              height: '40px',
              margin: '0 10px',
              border: '2px solid #000',
              cursor: 'pointer',
              borderRadius: '50%',
              backgroundColor: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              color: '#fff',
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
            onDoubleClick={() => handleDoubleClick({ clientX: window.innerWidth / 2, clientY: window.innerHeight / 2 })}
          >
            {getNextNumberForColor('red')}
          </div>
          <div
            draggable
            onDragStart={() => handleDragStart('ball', 'blue')} // Handle drag start
            onTouchStart={() => handleDragStart('ball', 'blue')} // Handle touch start
            style={{
              width: '40px',
              height: '40px',
              margin: '0 10px',
              border: '2px solid #000',
              cursor: 'pointer',
              borderRadius: '50%',
              backgroundColor: 'blue',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              color: '#fff',
              fontWeight: 'bold',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
            onDoubleClick={() => handleDoubleClick({ clientX: window.innerWidth / 2, clientY: window.innerHeight / 2 })}
          >
            {getNextNumberForColor('blue')}
          </div>
          <img
            src={image}
            alt="element"
            draggable
            onDragStart={() => handleDragStart('image', image)} // Handle drag start
            onTouchStart={() => handleDragStart('image', image)} // Handle touch start
            style={{
              width: '40px',
              height: '40px',
              margin: '0 10px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
      </div>

      <button
        onClick={handleSaveBoard}
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 15px',
          cursor: 'pointer',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          fontSize: '16px',
          zIndex: 1000,
        }}
      >
        Save Board
      </button>
    </div>
  );
};

export default TacticalBoard;
