/*
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const UpdateUserId = () => {
  const navigate = useNavigate();
  
  const [players, setPlayers] = useState([]);
  const [appUsers, setAppUsers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [formError, setFormError] = useState(null);

  // Fetch players and app users on component mount
  useEffect(() => {
    const fetchPlayers = async () => {
      const { data, error } = await supabase.from('players').select('id, Name');
      if (error) {
        console.error('Error fetching players:', error);
      } else {
        setPlayers(data);
      }
    };

    const fetchAppUsers = async () => {
      const { data, error } = await supabase.from('app_users').select('id, email');
      if (error) {
        console.error('Error fetching users:', error);
      } else {
        setAppUsers(data);
      }
    };

    fetchPlayers();
    fetchAppUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPlayer || !selectedUser) {
      setFormError('Please select both a player and a user.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('players')
        .update({ user_id: selectedUser })
        .eq('id', selectedPlayer);

      if (error) {
        console.error('Error updating user ID:', error);
        setFormError('Error updating the user ID. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Update successful:', data);
        setFormError(null);
        navigate('/players', { replace: true }); // Navigate back to the players page
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page update-user-id">
      <form onSubmit={handleSubmit}>
        <label htmlFor="player">Select Player:</label>
        <select 
          id="player" 
          value={selectedPlayer} 
          onChange={(e) => setSelectedPlayer(e.target.value)}
        >
          <option value="">--Select Player--</option>
          {players.map(player => (
            <option key={player.id} value={player.id}>
              {player.Name}
            </option>
          ))}
        </select>

        <label htmlFor="user">Select User:</label>
        <select 
          id="user" 
          value={selectedUser} 
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="">--Select User--</option>
          {appUsers.map(user => (
            <option key={user.id} value={user.id}>
              {user.email}
            </option>
          ))}
        </select>

        <button type="submit">Update User ID</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default UpdateUserId;
*/

/*
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const UpdatePlayerUserId = () => {
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState(new Set());
  const [selectedEmails, setSelectedEmails] = useState(new Set());
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [playerInput, setPlayerInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      const { data, error } = await supabase.from('players').select('id, Name');
      if (error) {
        console.error('Error fetching players:', error);
      } else {
        setPlayers(data);
        setFilteredPlayers(data); // Initialize filtered players
      }
    };

    const fetchProfiles = async () => {
      const { data, error } = await supabase.from('profiles').select('id, email');
      if (error) {
        console.error('Error fetching profiles:', error);
      } else {
        setProfiles(data);
        setFilteredEmails(data); // Initialize filtered emails
      }
    };

    fetchPlayers();
    fetchProfiles();
  }, []);

  const handlePlayerInputChange = (e) => {
    const value = e.target.value;
    setPlayerInput(value);
    setFilteredPlayers(
      players.filter(player => player.Name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleEmailInputChange = (e) => {
    const value = e.target.value;
    setEmailInput(value);
    setFilteredEmails(
      profiles.filter(profile => profile.email.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayers(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(player.id)) {
        newSelection.delete(player.id);
      } else {
        newSelection.add(player.id);
      }
      return newSelection;
    });
    setPlayerInput('');
    setFilteredPlayers(players); // Reset filter
  };

  const handleEmailSelect = (profile) => {
    setSelectedEmails(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(profile.email)) {
        newSelection.delete(profile.email);
      } else {
        newSelection.add(profile.email);
      }
      return newSelection;
    });
    setEmailInput('');
    setFilteredEmails(profiles); // Reset filter
  };

  const getUserIdByEmail = (email) => {
    const profile = profiles.find(profile => profile.email === email);
    return profile ? profile.id : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedPlayers.size === 0 || selectedEmails.size === 0) {
      setFormError('Please select at least one player and one email.');
      return;
    }

    try {
      for (const playerId of selectedPlayers) {
        for (const email of selectedEmails) {
          const userId = getUserIdByEmail(email);
          if (userId) {
            const { data, error } = await supabase
              .from('players')
              .update({ user_id: userId })
              .eq('id', playerId);

            if (error) {
              console.error('Error updating user ID:', error);
              setFormError('Error updating user ID. ' + (error.message || 'Unknown error'));
              return;
            }
          }
        }
      }

      console.log('Update successful');
      setFormError(null);
      navigate('/players', { replace: true }); // Navigate back to the players page
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page update-player-user-id">
      <h1>Update Player User ID</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <h2>Select Players:</h2>
          <input
            type="text"
            placeholder="Search players..."
            value={playerInput}
            onChange={handlePlayerInputChange}
          />
          <ul>
            {filteredPlayers.map(player => (
              <li key={player.id} onClick={() => handlePlayerSelect(player)}>
                <input
                  type="checkbox"
                  checked={selectedPlayers.has(player.id)}
                  readOnly
                />
                {player.Name}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h2>Select Emails:</h2>
          <input
            type="text"
            placeholder="Search emails..."
            value={emailInput}
            onChange={handleEmailInputChange}
          />
          <ul>
            {filteredEmails.map(profile => (
              <li key={profile.id} onClick={() => handleEmailSelect(profile)}>
                <input
                  type="checkbox"
                  checked={selectedEmails.has(profile.email)}
                  readOnly
                />
                {profile.email}
              </li>
            ))}
          </ul>
        </div>

        <button type="submit">Update User ID</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default UpdatePlayerUserId;
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const UpdatePlayerUserId = () => {
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState(new Set());
  const [selectedEmails, setSelectedEmails] = useState(new Set());
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [playerInput, setPlayerInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      const { data, error } = await supabase.from('players').select('id, Name');
      if (error) {
        console.error('Error fetching players:', error);
      } else {
        setPlayers(data);
        setFilteredPlayers(data); // Initialize filtered players
      }
    };

    const fetchProfiles = async () => {
      const { data, error } = await supabase.from('profiles').select('id, email');
      if (error) {
        console.error('Error fetching profiles:', error);
      } else {
        setProfiles(data);
        setFilteredEmails(data); // Initialize filtered emails
      }
    };

    fetchPlayers();
    fetchProfiles();
  }, []);

  const handlePlayerInputChange = (e) => {
    const value = e.target.value;
    setPlayerInput(value);
    setFilteredPlayers(
      players.filter(player => player.Name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handleEmailInputChange = (e) => {
    const value = e.target.value;
    setEmailInput(value);
    setFilteredEmails(
      profiles.filter(profile => profile.email.toLowerCase().includes(value.toLowerCase()))
    );
  };

  const handlePlayerSelect = (playerId) => {
    setSelectedPlayers(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(playerId)) {
        newSelection.delete(playerId);
      } else {
        newSelection.add(playerId);
      }
      return newSelection;
    });
  };

  const handleEmailSelect = (email) => {
    setSelectedEmails(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(email)) {
        newSelection.delete(email);
      } else {
        newSelection.add(email);
      }
      return newSelection;
    });
  };

  const getUserIdByEmail = (email) => {
    const profile = profiles.find(profile => profile.email === email);
    return profile ? profile.id : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedPlayers.size === 0 || selectedEmails.size === 0) {
      setFormError('Please select at least one player and one email.');
      return;
    }

    try {
      for (const playerId of selectedPlayers) {
        for (const email of selectedEmails) {
          const userId = getUserIdByEmail(email);
          if (userId) {
            const { data, error } = await supabase
              .from('players')
              .update({ user_id: userId })
              .eq('id', playerId);

            if (error) {
              console.error('Error updating user ID:', error);
              setFormError('Error updating user ID. ' + (error.message || 'Unknown error'));
              return;
            }
          }
        }
      }

      console.log('Update successful');
      setFormError(null);
      navigate('/players', { replace: true }); // Navigate back to the players page
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page update-player-user-id">
      <h1>Update Player User ID</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="input-section">
          <div>
            <h2>Select Players:</h2>
            <input
              type="text"
              placeholder="Search players..."
              value={playerInput}
              onChange={handlePlayerInputChange}
            />
            {playerInput && (filteredPlayers.length > 0) && (
              <ul className="list-box">
                {filteredPlayers.map(player => (
                  <li key={player.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedPlayers.has(player.id)}
                        onChange={() => handlePlayerSelect(player.id)}
                      />
                      {player.Name}
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div>
            <h2>Select Emails:</h2>
            <input
              type="text"
              placeholder="Search emails..."
              value={emailInput}
              onChange={handleEmailInputChange}
            />
            {emailInput && (filteredEmails.length > 0) && (
              <ul className="list-box">
                {filteredEmails.map(profile => (
                  <li key={profile.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedEmails.has(profile.email)}
                        onChange={() => handleEmailSelect(profile.email)}
                      />
                      {profile.email}
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <button type="submit">Update User ID</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default UpdatePlayerUserId;
