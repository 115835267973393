import React, { useEffect, useState } from 'react';
import supabase from "../config/supabaseClient";
import { useNavigate, useParams } from "react-router-dom";

const AddExercisesPage = () => {
  const { practiceId } = useParams(); // Get practiceId from the URL
  const [exercises, setExercises] = useState([]); // All exercises fetched from Supabase
  const [selectedExercises, setSelectedExercises] = useState([]); // Exercises selected by the user
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises')
          .select('id, file_url, resume'); // Fetch the necessary columns

        if (error) {
          throw error;
        }

        setExercises(data);
      } catch (err) {
        setError('An error occurred: ' + err.message);
      }
    };

    fetchExercises();
  }, []);

  const handleExerciseSelect = (exerciseId) => {
    setSelectedExercises((prevSelected) => {
      if (prevSelected.includes(exerciseId)) {
        return prevSelected.filter(id => id !== exerciseId);
      } else {
        return [...prevSelected, exerciseId];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      if (!practiceId) {
        throw new Error('Practice ID is not defined.');
      }

      const exercisesToInsert = selectedExercises.map(exerciseId => ({
        practice_id: practiceId,
        exercise_id: exerciseId
      }));

      console.log('Exercises to insert:', exercisesToInsert); // Debugging line

      const { error } = await supabase
        .from('practice_exercises')
        .upsert(exercisesToInsert, { onConflict: ['practice_id', 'exercise_id'] });

      if (error) {
        throw error;
      }

      navigate(`/practice-details/${practiceId}`);
    } catch (err) {
      setError('An error occurred while adding exercises: ' + err.message);
    }
  };

  return (
    <div className="page add-exercises">
      <style>{`
        .page {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        h1 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        .exercises-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          margin-top: 20px;
        }
        .exercise-item {
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          padding: 15px;
          text-align: center;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        .exercise-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        }
        .exercise-image {
          max-width: 100%;
          height: auto;
          border-radius: 4px;
        }
        .exercise-resume {
          margin-top: 10px;
          font-size: 14px;
          color: #555;
        }
        .selected {
          border: 2px solid green;
        }
        .submit-btn {
          display: block;
          margin: 20px auto;
          padding: 10px 20px;
          font-size: 16px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .submit-btn:hover {
          background-color: #218838;
        }
        .error {
          color: red;
          text-align: center;
        }
      `}</style>

      <h1>Select Exercises</h1>

      {error && <p className="error">{error}</p>}

      <div className="exercises-container">
        {exercises.map((exercise) => (
          <div
            key={exercise.id}
            className={`exercise-item ${selectedExercises.includes(exercise.id) ? 'selected' : ''}`}
            onClick={() => handleExerciseSelect(exercise.id)}
          >
            <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
            <p className="exercise-resume">{exercise.resume}</p>
          </div>
        ))}
      </div>

      {selectedExercises.length > 0 && (
        <button className="submit-btn" onClick={handleSubmit}>
          Add Selected Exercises
        </button>
      )}
    </div>
  );
};

export default AddExercisesPage;
