import React, { useState } from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import { gpx } from '@mapbox/togeojson';
import 'leaflet/dist/leaflet.css';

// Helper function to calculate the distance between two points using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Radius of the Earth in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};

const GpxUploader = () => {
  const [gpxData, setGpxData] = useState(null);
  const [distance, setDistance] = useState(0);
  const [averageVelocity, setAverageVelocity] = useState(0);
  const [maxVelocity, setMaxVelocity] = useState(0);
  const [minAltitude, setMinAltitude] = useState(null);
  const [maxAltitude, setMaxAltitude] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(e.target.result, 'application/xml');
      const geojson = gpx(xml);

      // Extracting coordinates and elevation for the Polyline component
      const coordinates = geojson.features[0].geometry.coordinates.map(coord => [coord[1], coord[0]]);
      const elevations = geojson.features[0].geometry.coordinates.map(coord => coord[2]).filter(ele => ele !== undefined);

      // Calculate total distance
      let totalDistance = 0;
      let maxSpeed = 0;

      // Extracting time data for velocity calculation (if available)
      const times = geojson.features[0].properties.coordTimes;

      if (times) {
        for (let i = 1; i < coordinates.length; i++) {
          // Calculate distance between consecutive points
          const segmentDistance = calculateDistance(
            coordinates[i - 1][0],
            coordinates[i - 1][1],
            coordinates[i][0],
            coordinates[i][1]
          );
          totalDistance += segmentDistance;

          // Calculate time difference in hours
          const timeDifference = (new Date(times[i]) - new Date(times[i - 1])) / 3600000; // Convert ms to hours

          if (timeDifference > 0) {
            // Calculate speed in km/h
            const speed = (segmentDistance / 1000) / timeDifference;
            if (speed > maxSpeed) {
              maxSpeed = speed;
            }
          }
        }

        // Calculate total time
        const startTime = new Date(times[0]);
        const endTime = new Date(times[times.length - 1]);
        const totalTimeInSeconds = (endTime - startTime) / 1000; // Total time in seconds
        const totalTimeInHours = totalTimeInSeconds / 3600; // Convert to hours

        // Calculate average velocity
        const averageSpeed = totalDistance / 1000 / totalTimeInHours; // Velocity in km/h
        setAverageVelocity(averageSpeed);
      }

      setMaxVelocity(maxSpeed);
      setDistance(totalDistance / 1000); // Convert to kilometers

      // Calculate min and max altitude
      if (elevations.length > 0) {
        setMinAltitude(Math.min(...elevations));
        setMaxAltitude(Math.max(...elevations));
      } else {
        setMinAltitude(null);
        setMaxAltitude(null);
      }

      setGpxData(coordinates);
    };

    reader.readAsText(file);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>GPX File Viewer</h1>
      <input 
        type="file" 
        accept=".gpx" 
        onChange={handleFileUpload} 
        style={{ margin: '20px', padding: '10px' }} 
      />

      {gpxData && (
        <>
          <MapContainer 
            center={gpxData[0]} 
            zoom={13} 
            style={{ height: '400px', width: '90%', margin: '20px auto' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Polyline positions={gpxData} color="blue" />
          </MapContainer>
          <div style={{ marginTop: '20px' }}>
            <p><strong>Total Distance:</strong> {distance.toFixed(2)} km</p>
            <p><strong>Average Velocity:</strong> {averageVelocity.toFixed(2)} km/h</p>
            <p><strong>Maximum Velocity:</strong> {maxVelocity.toFixed(2)} km/h</p>
            {minAltitude !== null && maxAltitude !== null && (
              <>
                <p><strong>Minimum Altitude:</strong> {minAltitude.toFixed(2)} meters</p>
                <p><strong>Maximum Altitude:</strong> {maxAltitude.toFixed(2)} meters</p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GpxUploader;
