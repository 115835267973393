// src/pages/TalkArchive.js

import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function TalkArchive() {
  const { id } = useParams();
  const [talks, setTalks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTalkArchive = async () => {
      try {
        const { data, error } = await supabase
          .from('talk_archive')
          .select('type, Name, Date, Resume')
          .eq('player_id', id);

        if (error) {
          throw error;
        }

        setTalks(data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTalkArchive();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (talks.length === 0) return <div style={styles.error}>No talks found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Talk Archive</h2>
      <ul style={styles.list}>
        {talks.map((talk, index) => (
          <li key={index} style={styles.item}>
            <p><strong>Type:</strong> {talk.type}</p>
            <p><strong>Name:</strong> {talk.Name}</p>
            <p><strong>Date:</strong> {new Date(talk.Date).toLocaleString()}</p>
            <p><strong>Resume:</strong> {talk.Resume}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '800px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  item: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
};

export default TalkArchive;
