import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient'; // Ensure this is the correct path

const ExternalScoutingCard = ({ talk, onDelete }) => {
  
  return (
    <div className="smoothie-card">
      <h3>{talk.type}</h3>
      <h3><strong>Player Name:</strong> {talk.Name}</h3>
      <p><strong>Number:</strong> {talk.Number}</p>
      <p><strong>Birth_date</strong> {talk.Birth_date}</p>
      <p><strong>Club:</strong> {talk.Club}</p>
      <p><strong>Telephone_number:</strong> {talk.Telephone_number}</p>
      <p><strong>Position:</strong> {talk.Position}</p>
      <p><strong>Grade:</strong> {talk.Grade}</p>
      <p><strong>Date:</strong> {new Date(talk.Date).toLocaleDateString()}</p>
      <p><strong>Coment:</strong> {talk.coment}</p>
    </div>
  );
};

export default ExternalScoutingCard;
