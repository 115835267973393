/*
import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';

const ViewTalkArchive = () => {
  const [talks, setTalks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at')

  useEffect(() => {
    const fetchTalks = async () => {
      try {
        const { data, error } = await supabase
          .from('talk_archive')
          .select('*')
          .order(orderBy, {ascending: false})

        if (error) throw error;
        setTalks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTalks();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div>
      <h1>Talk Archive</h1>
      {talks.length === 0 ? (
        <p>No records found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Player ID</th>
              <th>Name</th>
              <th>Date</th>
              <th>Resume</th>
            </tr>
          </thead>
          <tbody>
            {talks.map((talk) => (
              <tr key={talk.id}>
                <td>{talk.type}</td>
                <td>{talk.player_id || 'N/A'}</td>
                <td>{talk.Name || 'N/A'}</td>
                <td>{new Date(talk.Date).toLocaleDateString()}</td>
                <td>{talk.Resume}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ViewTalkArchive;
*/

/*
import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import TalkCard from '../components/TalkCard'; // You need to create this component

const ViewTalkArchive = () => {
  const [talks, setTalks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at');

  // Fetch talks from Supabase
  useEffect(() => {
    const fetchTalks = async () => {
      try {
        const { data, error } = await supabase
          .from('talk_archive')
          .select('*')
          .order(orderBy, { ascending: false });

        if (error) throw error;
        setTalks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTalks();
  }, [orderBy]);

  // Handle delete action
  const handleDelete = (id) => {
    setTalks(prevTalks => prevTalks.filter(talk => talk.id !== id));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="page home">
      <div className="order-by">
        <p>Order by:</p>
        <button onClick={() => setOrderBy('type')}>Type</button>
        <button onClick={() => setOrderBy('player_id')}>Player ID</button>
        <button onClick={() => setOrderBy('Name')}>Name</button>
        <button onClick={() => setOrderBy('Date')}>Date</button>
      </div>
      <div className="talk-cards">
        {talks.length === 0 ? (
          <p>No records found.</p>
        ) : (
          talks.map(talk => (
            <TalkCard key={talk.id} talk={talk} onDelete={handleDelete} />
          ))
        )}
      </div>
    </div>
  );
};

export default ViewTalkArchive;
*/

import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import TalkCard from '../components/TalkCard'; // Import the TalkCard component

const ViewTalkArchive = () => {
  const [talks, setTalks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at');

  // Fetch talks from Supabase
  useEffect(() => {
    const fetchTalks = async () => {
      try {
        const { data, error } = await supabase
          .from('talk_archive')
          .select('*')
          .order(orderBy, { ascending: false });

        if (error) throw error;
        setTalks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTalks();
  }, [orderBy]);

  // Handle delete action
  const handleDelete = (id) => {
    setTalks(prevTalks => prevTalks.filter(talk => talk.id !== id));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="page home">
      <div className="order-by">
        <p>Order by:</p>
        <button onClick={() => setOrderBy('type')}>Type</button>
        <button onClick={() => setOrderBy('player_id')}>Player ID</button>
        <button onClick={() => setOrderBy('Name')}>Name</button>
        <button onClick={() => setOrderBy('Date')}>Date</button>
      </div>
      <div className="smoothie-grid">
        {talks.length === 0 ? (
          <p>No records found.</p>
        ) : (
          talks.map(talk => (
            <TalkCard key={talk.id} talk={talk} onDelete={handleDelete} />
          ))
        )}
      </div>
    </div>
  );
};

export default ViewTalkArchive;
