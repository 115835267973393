import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import your CSS file if you have one

const Admin = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Admin</h1>
        <p>Select a page to visit:</p>
      </header>
      <main className="home-content">
        <nav className="home-nav">
          <ul>
            <li>
              <Link to="/UpdateUserId">Update User Preference</Link>
            </li>
            <li>
              <Link to="/admin">Admin</Link>
            </li>
            <li>
              <Link to="/admin">Admin</Link>
            </li>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/">Log-out</Link>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

export default Admin;
