import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient'; // Ensure this is the correct path

const InternalScoutingCard = ({ talk, onDelete }) => {
  const [playerName, setPlayerName] = useState('Loading...');

  useEffect(() => {
    const fetchPlayerName = async () => {
      if (talk.player_id) {
        try {
          const { data, error } = await supabase
            .from('players')
            .select('Name')
            .eq('id', talk.player_id)
            .single();

          if (error) {
            console.error('Error fetching player name:', error);
            setPlayerName('Error fetching name');
          } else if (data) {
            setPlayerName(data.Name);
          } else {
            setPlayerName('Player not found');
          }
        } catch (err) {
          console.error('Unexpected error:', err);
          setPlayerName('Error fetching name');
        }
      } else {
        setPlayerName('N/A');
      }
    };

    fetchPlayerName();
  }, [talk.player_id]);

  return (
    <div className="smoothie-card">
      <h3>{talk.type}</h3>
      <p><strong>Player ID:</strong> {talk.player_id || 'N/A'}</p>
      <p><strong>Player Name:</strong> {playerName}</p>
      <p><strong>Date:</strong> {new Date(talk.Date).toLocaleDateString()}</p>
      <p><strong>Coment:</strong> {talk.coment}</p>
    </div>
  );
};

export default InternalScoutingCard;
