/*
import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function GameEvents() {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchGameEvents = async () => {
      try {
        const { data, error } = await supabase
          .from('game_event')
          .select('game_id, type, minute')
          .eq('player_id', id);

        if (error) {
          throw error;
        }

        setEvents(data);
      } catch (error) {
        console.error('Error fetching game events:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameEvents();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!events.length) return <div style={styles.error}>No game events found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Game Events</h2>
      <div style={styles.eventList}>
        {events.map((event, index) => (
          <div key={index} style={styles.event}>
            <p><strong>Game ID:</strong> {event.game_id}</p>
            <p><strong>Type:</strong> {event.type}</p>
            <p><strong>Minute:</strong> {event.minute}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  eventList: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  event: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default GameEvents;
*/

// src/pages/GameEvents.js

import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function GameEvents() {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchGameEvents = async () => {
      try {
        // Fetch game events along with the Journey field from the game table
        const { data: gameEvents, error: gameEventsError } = await supabase
          .from('game_event')
          .select('game_id, type, minute, game (Journey)')
          .eq('player_id', id);

        if (gameEventsError) {
          throw gameEventsError;
        }

        setEvents(gameEvents);
      } catch (error) {
        console.error('Error fetching game events:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameEvents();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!events.length) return <div style={styles.error}>No game events found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Game Events</h2>
      <div style={styles.eventList}>
        {events.map((event, index) => (
          <div key={index} style={styles.event}>
            <p><strong>Journey:</strong> {event.game.Journey}</p>
            <p><strong>Type:</strong> {event.type}</p>
            <p><strong>Minute:</strong> {event.minute}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  eventList: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  event: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default GameEvents;
