import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import Querie_report from '../components/Querie_report4';

const View_test_records = () => {
  const [items, setItems] = useState([]);
  const [players, setPlayers] = useState([]); // State to hold player data
  const [tests, setTests] = useState([]); // State to hold test data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        // Fetch test records
        const { data: testRecords, error: testError } = await supabase
          .from('test_record')
          .select('*')
          .order(orderBy, { ascending: false });

        if (testError) throw testError;

        // Fetch players
        const { data: playerData, error: playerError } = await supabase
          .from('players')
          .select('*');

        if (playerError) throw playerError;

        // Fetch tests
        const { data: testData, error: testFetchError } = await supabase
          .from('tests')
          .select('*');

        if (testFetchError) throw testFetchError;

        // Set the fetched data to state
        setItems(testRecords); // Set test records
        setPlayers(playerData); // Set player data
        setTests(testData); // Set test data

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchData();
  }, [orderBy]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="page home">
      <div className="smoothie-grid">
        {items.length === 0 ? (
          <p>No records found.</p>
        ) : (
          items.map(item => (
            <Querie_report 
              key={item.id} 
              item={item} 
              players={players} // Pass players array
              tests={tests} // Pass tests array
            />
          ))
        )}
      </div>
    </div>
  );
};

export default View_test_records;
