/*
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import supabase from '../config/supabaseClient';


const SetupPassword = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate(); // Replaces useHistory

  // Extract the email from the query parameters
  const query = new URLSearchParams(location.search);
  const email = query.get("email");

  useEffect(() => {
    if (!email) {
      setMessage("No email provided.");
    }
  }, [email]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    if (!password || password.length < 6) {
      setMessage("Password must be at least 6 characters.");
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        email,
        password,
      });

      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage("Password updated successfully! You can now log in.");
        setTimeout(() => {
          navigate("/login"); // Replaces history.push("/login")
        }, 2000);
      }
    } catch (err) {
      setMessage("An unexpected error occurred.");
    }
  };

  return (
    <div>
      <h2>Set Your Password</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handlePasswordSubmit}>
        <div>
          <label htmlFor="password">New Password: </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Set Password</button>
      </form>
    </div>
  );
};

export default SetupPassword;
*/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from '../config/supabaseClient';

const SetupPassword = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error || !data.session) {
        setMessage("No active session found. Please log in again.");
        navigate("/signup");
      }
    };

    checkSession();
  }, [navigate]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    if (!password || password.length < 6) {
      setMessage("Password must be at least 6 characters.");
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password,
      });

      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage("Password updated successfully! You can now log in.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (err) {
      setMessage("An unexpected error occurred.");
    }
  };

  return (
    <div>
      <h2>Set Your Password</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handlePasswordSubmit}>
        <div>
          <label htmlFor="password">New Password: </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Set Password</button>
      </form>
    </div>
  );
};

export default SetupPassword;
