import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const CreateRecord = () => {
  const navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerName, setPlayerName] = useState('');
  const [report, setReport] = useState('');
  const [stop_time, setStop_time] = useState('');
  const [formError, setFormError] = useState(null);
  const [buttonText, setButtonText] = useState('Create Record');  // Add state for button text

  useEffect(() => {
    const fetchPlayers = async () => {
      const { data, error } = await supabase
        .from('players')  // Ensure this matches your actual table name
        .select('id, Name');
        
      if (error) {
        console.error('Supabase error fetching players:', error);
      } else {
        setPlayers(data);
      }
    };

    fetchPlayers();
  }, []);

  const handlePlayerNameChange = (e) => {
    const input = e.target.value;
    setPlayerName(input);
    
    if (input) {
      const filtered = players.filter(player => player.Name.toLowerCase().includes(input.toLowerCase()));
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
    setPlayerName(player.Name);
    setFilteredPlayers([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPlayer || ! report || !stop_time) {
      setFormError('Please fill in all the fields correctly.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('medical_report')  // Ensure this matches your actual table name
        .insert([{ player_id: selectedPlayer.id, report, stop_time }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/medical', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  // Update button text based on form state
  useEffect(() => {
    if (!selectedPlayer || ! report || !stop_time) {
      setButtonText('Please fill in all fields');
    } else {
      setButtonText('Create Record');
    }
  }, [selectedPlayer, report , stop_time]);

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="playerName">Player Name:</label>
        <input 
          type="text"
          id="playerName"
          value={playerName}
          onChange={handlePlayerNameChange}
        />
        {filteredPlayers.length > 0 && (
          <ul className="suggestions">
            {filteredPlayers.map(player => (
              <li key={player.id} onClick={() => handlePlayerSelect(player)}>
                {player.Name}
              </li>
            ))}
          </ul>
        )}

        <label htmlFor="report">Report:</label>
        <input 
          type="text"
          id="report"
          value={report}
          onChange={(e) => setReport(e.target.value)}
        />

        <label htmlFor="stop_time">Stop Time:</label>
        <input 
          type="text"
          id="stop_time"
          value={stop_time}
          onChange={(e) => setStop_time(e.target.value)}
        />

        <button type="submit">{buttonText}</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default CreateRecord;
