/*
import React, { useState, useEffect } from 'react';
import supabase from "../config/supabaseClient";

const handleSignUp = async (e) => {
    e.preventDefault();
  
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: 'https://teamteste.vercel.app/setup-password' // <-- Ensure full URL with path
      }
    });
  
    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage("Check your email for the magic link!");
    }
  };
  export default handleSignUp;
  
*/

import React, { useState } from 'react';
import supabase from "../config/supabaseClient";

const HandleSignUp = () => {
  // Define the email and message state
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: 'https://demo.footbased.uk/setup-password' // Ensure full URL with path
      }
    });

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage("Check your email for the magic link!");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "50px" }}>
      <h2>Sign Up with Email</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "10px", width: "100%" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <button type="submit" style={{ padding: "10px", width: "100%" }}>
            Send Magic Link
          </button>
        </div>
      </form>
      {message && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default HandleSignUp;
