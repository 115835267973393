import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function TestRecords() {
  const { id } = useParams();
  const [testRecords, setTestRecords] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchTestRecords = async () => {
      try {
        // Fetch test records along with the test_name from the tests table
        const { data: testRecordsData, error: testRecordsError } = await supabase
          .from('test_record')
          .select('date, value, tests (test_name)')
          .eq('player_id', id);

        if (testRecordsError) {
          throw testRecordsError;
        }

        setTestRecords(testRecordsData);
      } catch (error) {
        console.error('Error fetching test records:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestRecords();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!testRecords.length) return <div style={styles.error}>No test records found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Test Records</h2>
      <div style={styles.recordList}>
        {testRecords.map((record, index) => (
          <div key={index} style={styles.record}>
            <p><strong>Test Name:</strong> {record.tests.test_name}</p>
            <p><strong>Date:</strong> {new Date(record.date).toLocaleDateString()}</p>
            <p><strong>Value:</strong> {record.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  recordList: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  record: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default TestRecords;
