/*
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../index.css';

const PracticeDetailPage = ({ practiceId, onBack }) => {
  const [practice, setPractice] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [medicalDepartmentCount, setMedicalDepartmentCount] = useState(0);
  const [justifiedAbsenceCount, setJustifiedAbsenceCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPracticeDetails = async () => {
      try {
        const { data, error } = await supabase
          .rpc('get_practice_detailes', { practice_id_param: practiceId });

        if (error) {
          throw error;
        }

        if (data.length > 0) {
          setPractice(data);

          const { count: presentCount, error: presentCountError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Present');

          if (presentCountError) {
            throw presentCountError;
          }
          setPresentCount(presentCount);

          const { count: medicalDepartmentCount, error: medicalDeptError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Medical Department');

          if (medicalDeptError) {
            throw medicalDeptError;
          }
          setMedicalDepartmentCount(medicalDepartmentCount);

          const { count: justifiedAbsenceCount, error: justifiedAbsenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Justified Absence');

          if (justifiedAbsenceError) {
            throw justifiedAbsenceError;
          }
          setJustifiedAbsenceCount(justifiedAbsenceCount);

          const { count: absenceCount, error: absenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Absence');

          if (absenceError) {
            throw absenceError;
          }
          setAbsenceCount(absenceCount);

        } else {
          setError('No practice details found.');
        }
      } catch (error) {
        setError('Error fetching practice details: ' + (error.message || 'Unknown error'));
      }
    };

    fetchPracticeDetails();
  }, [practiceId]);

  const totalCount = presentCount + medicalDepartmentCount + justifiedAbsenceCount + absenceCount;
  const presentPercentage = totalCount ? ((presentCount / totalCount) * 100).toFixed(2) : 0;
  const medicalDepartmentPercentage = totalCount ? ((medicalDepartmentCount / totalCount) * 100).toFixed(2) : 0;
  const justifiedAbsencePercentage = totalCount ? ((justifiedAbsenceCount / totalCount) * 100).toFixed(2) : 0;
  const absencePercentage = totalCount ? ((absenceCount / totalCount) * 100).toFixed(2) : 0;

  const handleAddExercises = () => {
    navigate(`/add-exercises/${practiceId}`);
  };

  const handleViewExercises = () => {
    navigate(`/exercises-list/${practiceId}`);
  };

  return (
    <div className="page practice-detail">
      <button onClick={onBack}>Back to Practices</button>
      {error && (<p className="error">{error}</p>)}
      {practice.length > 0 && (
        <div>
          <div className="practice-info">
            <h2>Practice Details</h2>
            <p><strong>Practice ID:</strong> {practice[0].practice_id}</p>
            <p><strong>Date:</strong> {practice[0].practice_date}</p>
            <p><strong>Journey:</strong> {practice[0].practice_journey}</p>
            <p><strong>Time:</strong> {practice[0].practice_time}</p>
            <p><strong>Weather:</strong> {practice[0].practice_weather}</p>
            <p><strong>Total Present Count:</strong> {presentCount}</p>
            <p><strong>Medical Department Count:</strong> {medicalDepartmentCount}</p>
            <p><strong>Justified Absence Count:</strong> {justifiedAbsenceCount}</p>
            <p><strong>Absence Count:</strong> {absenceCount}</p>
            <p><strong>Present Percentage:</strong> {presentPercentage}%</p>
          </div>
          <h3>Players</h3>
          {practice.map((detail, index) => (
            <div key={index} className="player-details">
              <p><strong>Player Name:</strong> {detail.player_name}</p>
              <p><strong>Presence Status:</strong> {detail.presence_status}</p>
              <hr />
            </div>
          ))}

          {}
          <button onClick={handleAddExercises}>Add Exercises</button>

          {}
          <button onClick={handleViewExercises}>View Exercises</button>
        </div>
      )}
    </div>
  );
};

export default PracticeDetailPage;
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import { jsPDF } from "jspdf"; // Import jsPDF
import '../index.css';

const PracticeDetailPage = ({ practiceId, onBack }) => {
  const [practice, setPractice] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [medicalDepartmentCount, setMedicalDepartmentCount] = useState(0);
  const [justifiedAbsenceCount, setJustifiedAbsenceCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPracticeDetails = async () => {
      try {
        const { data, error } = await supabase
          .rpc('get_practice_detailes', { practice_id_param: practiceId });

        if (error) {
          throw error;
        }

        if (data.length > 0) {
          setPractice(data);

          const { count: presentCount, error: presentCountError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Present');

          if (presentCountError) {
            throw presentCountError;
          }
          setPresentCount(presentCount);

          const { count: medicalDepartmentCount, error: medicalDeptError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Medical Department');

          if (medicalDeptError) {
            throw medicalDeptError;
          }
          setMedicalDepartmentCount(medicalDepartmentCount);

          const { count: justifiedAbsenceCount, error: justifiedAbsenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Justified Absence');

          if (justifiedAbsenceError) {
            throw justifiedAbsenceError;
          }
          setJustifiedAbsenceCount(justifiedAbsenceCount);

          const { count: absenceCount, error: absenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Absence');

          if (absenceError) {
            throw absenceError;
          }
          setAbsenceCount(absenceCount);

        } else {
          setError('No practice details found.');
        }
      } catch (error) {
        setError('Error fetching practice details: ' + (error.message || 'Unknown error'));
      }
    };

    fetchPracticeDetails();
  }, [practiceId]);

  const totalCount = presentCount + medicalDepartmentCount + justifiedAbsenceCount + absenceCount;
  const presentPercentage = totalCount ? ((presentCount / totalCount) * 100).toFixed(2) : 0;
  const medicalDepartmentPercentage = totalCount ? ((medicalDepartmentCount / totalCount) * 100).toFixed(2) : 0;
  const justifiedAbsencePercentage = totalCount ? ((justifiedAbsenceCount / totalCount) * 100).toFixed(2) : 0;
  const absencePercentage = totalCount ? ((absenceCount / totalCount) * 100).toFixed(2) : 0;

  const handleAddExercises = () => {
    navigate(`/add-exercises/${practiceId}`);
  };

  const handleViewExercises = () => {
    navigate(`/exercises-list/${practiceId}`);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
  
    // Add title
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text("Practice Details", 105, 20, null, null, 'center');  // Title centered
  
    // Add practice details header
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text("Practice Information:", 20, 30);
  
    // Practice details content (non-bold)
    doc.setFont('helvetica', 'normal');
    if (practice.length > 0) {
      const practiceDetail = practice[0];
      let yPosition = 40;
      const lineSpacing = 10;
  
      doc.text(`Practice ID: ${practiceDetail.practice_id}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Date: ${practiceDetail.practice_date}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Journey: ${practiceDetail.practice_journey}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Time: ${practiceDetail.practice_time}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Weather: ${practiceDetail.practice_weather}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Total Present Count: ${presentCount}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Medical Department Count: ${medicalDepartmentCount}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Justified Absence Count: ${justifiedAbsenceCount}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Absence Count: ${absenceCount}`, 20, yPosition);
      yPosition += lineSpacing;
      doc.text(`Present Percentage: ${presentPercentage}%`, 20, yPosition);
  
      // Add section for Player details
      yPosition += lineSpacing + 10;  // Extra space before the next section
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text("Player Details:", 20, yPosition);
  
      yPosition += lineSpacing;
  
      // Table Header
      const startX = 20;
      const colWidths = [80, 50];  // Adjusted column widths for a nicer look
      let rowHeight = 10;
  
      // Draw table header with background color
      doc.setFillColor(200, 200, 200);  // Light grey background for header
      doc.rect(startX, yPosition, colWidths[0], rowHeight, 'F');  // Filled rectangle for Player Name header
      doc.rect(startX + colWidths[0], yPosition, colWidths[1], rowHeight, 'F');  // Filled rectangle for Presence Status header
  
      doc.setFont('helvetica', 'bold');
      doc.text('Player Name', startX + colWidths[0] / 2, yPosition + 7, null, null, 'center'); // Centered Player Name header
      doc.text('Presence Status', startX + colWidths[0] + colWidths[1] / 2, yPosition + 7, null, null, 'center'); // Centered Presence Status header
  
      yPosition += rowHeight; // Move Y position down for the next row
  
      // Draw player details in table rows
      doc.setFont('helvetica', 'normal');
      practice.forEach((detail, index) => {
        if (yPosition > 270) {  // Add a new page if the content exceeds the page height
          doc.addPage();
          yPosition = 20;  // Reset Y position for new page
  
          // Redraw the table headers on the new page
          doc.setFillColor(200, 200, 200);  // Light grey background for header
          doc.rect(startX, yPosition, colWidths[0], rowHeight, 'F');  // Filled rectangle for Player Name header
          doc.rect(startX + colWidths[0], yPosition, colWidths[1], rowHeight, 'F');  // Filled rectangle for Presence Status header
  
          doc.setFont('helvetica', 'bold');
          doc.text('Player Name', startX + colWidths[0] / 2, yPosition + 7, null, null, 'center');
          doc.text('Presence Status', startX + colWidths[0] + colWidths[1] / 2, yPosition + 7, null, null, 'center');
  
          yPosition += rowHeight; // Move Y position down for the next row
          doc.setFont('helvetica', 'normal');
        }
  
        // Alternating row background color for better readability
        if (index % 2 === 0) {
          doc.setFillColor(240, 240, 240);  // Light grey for even rows
          doc.rect(startX, yPosition, colWidths[0], rowHeight, 'F');  // Fill Player Name cell
          doc.rect(startX + colWidths[0], yPosition, colWidths[1], rowHeight, 'F');  // Fill Presence Status cell
        }
  
        // Draw Player Name and Presence Status in their respective columns
        doc.text(detail.player_name, startX + 5, yPosition + 7);  // Left-aligned Player Name
        doc.text(detail.presence_status, startX + colWidths[0] + 5, yPosition + 7);  // Left-aligned Presence Status
  
        yPosition += rowHeight;  // Move Y position down for the next row
      });
    }
  
    // Save the PDF
    doc.save("practice-details.pdf");
  };
  
  
  return (
    <div className="page practice-detail">
      <button onClick={onBack}>Back to Practices</button>
      {error && (<p className="error">{error}</p>)}
      {practice.length > 0 && (
        <div>
          <div className="practice-info">
            <h2>Practice Details</h2>
            <p><strong>Practice ID:</strong> {practice[0].practice_id}</p>
            <p><strong>Date:</strong> {practice[0].practice_date}</p>
            <p><strong>Journey:</strong> {practice[0].practice_journey}</p>
            <p><strong>Time:</strong> {practice[0].practice_time}</p>
            <p><strong>Weather:</strong> {practice[0].practice_weather}</p>
            <p><strong>Total Present Count:</strong> {presentCount}</p>
            <p><strong>Medical Department Count:</strong> {medicalDepartmentCount}</p>
            <p><strong>Justified Absence Count:</strong> {justifiedAbsenceCount}</p>
            <p><strong>Absence Count:</strong> {absenceCount}</p>
            <p><strong>Present Percentage:</strong> {presentPercentage}%</p>
          </div>
          <h3>Players</h3>
          {practice.map((detail, index) => (
            <div key={index} className="player-details">
              <p><strong>Player Name:</strong> {detail.player_name}</p>
              <p><strong>Presence Status:</strong> {detail.presence_status}</p>
              <hr />
            </div>
          ))}

          {/* Add Exercises Button */}
          <button onClick={handleAddExercises}>Add Exercises</button>

          {/* View Exercises Button */}
          <button onClick={handleViewExercises}>View Exercises</button>

          {/* Download PDF Button */}
          <button onClick={handleDownloadPDF}>Download PDF</button>
        </div>
      )}
    </div>
  );
};

export default PracticeDetailPage;
