import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const PracticePresencePage = () => {
  const navigate = useNavigate();

  const [practiceId, setPracticeId] = useState('');
  const [statusMap, setStatusMap] = useState({}); // Track status for each player
  const [formError, setFormError] = useState(null);
  const [practices, setPractices] = useState([]);
  const [players, setPlayers] = useState([]);

  // Define status options
  const statusOptions = [
    'Non Related',
    'Present',
    'Medical Department',
    'Justified Absence',
    'Absence'
  ];

  // Fetch practices and players on component mount
  useEffect(() => {
    const fetchPractices = async () => {
      const { data, error } = await supabase
        .from('practices') // Adjust the table name as needed
        .select('id, date, time'); // Assuming practices table has 'id' and 'date' fields

      if (error) {
        console.error('Error fetching practices:', error);
        setFormError('Error fetching practices.');
      } else {
        // Sort practices by date in descending order (latest dates first)
        const sortedPractices = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPractices(sortedPractices);
      }
    };

    const fetchPlayers = async () => {
      const { data, error } = await supabase
        .from('players') // Adjust the table name as needed
        .select('id, Name'); // Ensure this matches your schema

      if (error) {
        console.error('Error fetching players:', error);
        setFormError('Error fetching players.');
      } else {
        setPlayers(data);
        // Initialize status map with default status for each player
        const initialStatusMap = data.reduce((acc, player) => {
          acc[player.id] = statusOptions[0]; // Default status is the first option
          return acc;
        }, {});
        setStatusMap(initialStatusMap);
      }
    };

    fetchPractices();
    fetchPlayers();
  }, []);

  const handleStatusChange = (playerId, status) => {
    setStatusMap(prevStatusMap => ({
      ...prevStatusMap,
      [playerId]: status
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!practiceId || Object.values(statusMap).some(status => !status)) {
      setFormError('Please fill in all the fields.');
      return;
    }

    const records = Object.keys(statusMap).map(playerId => ({
      player_id: playerId,
      practice_id: practiceId,
      status: statusMap[playerId]
    }));

    try {
      const { data, error } = await supabase
        .from('practices_presence') // Ensure this matches your actual table name
        .insert(records); // Inserting multiple records

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/view_practices', { replace: true });  // Adjust this path as needed
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="practice_id">Practice:</label>
        <select
          id="practice_id"
          value={practiceId}
          onChange={(e) => setPracticeId(e.target.value)}
        >
          <option value="">Select a practice</option>
          {practices.map(practice => (
            <option key={practice.id} value={practice.id}>
              {practice.date} {/* Display practice date or any other relevant information */}
              {practice.time} {/* Display practice time or any other relevant information */}
            </option>
          ))}
        </select>

        <div>
          <h3>Player Statuses</h3>
          {players.map(player => (
            <div key={player.id}>
              <label htmlFor={`status_${player.id}`}>{player.Name}:</label>
              <select
                id={`status_${player.id}`}
                value={statusMap[player.id]}
                onChange={(e) => handleStatusChange(player.id, e.target.value)}
              >
                {statusOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>

        <button type="submit">Create Presence Records</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default PracticePresencePage;
