import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const CreateTalkArchive = () => {
  const navigate = useNavigate();

  const [type, setType] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [playerId, setPlayerId] = useState('');
  const [Name, setName] = useState('');
  const [Date, setDate] = useState('');
  const [Resume, setResume] = useState('');
  const [formError, setFormError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (playerName) {
        const { data, error } = await supabase
          .from('players')
          .select('id, Name')
          .ilike('Name', `%${playerName}%`); // Case-insensitive search

        if (error) {
          console.error('Error fetching player suggestions:', error);
          return;
        }
        setSuggestions(data || []);
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [playerName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!type || !Date || !Resume) {
      setFormError('Please fill in all required fields.');
      return;
    }

    // Use selectedPlayerId if type is 'player'
    const playerIdNumber = (type === 'player' || type === 'parent') ? selectedPlayerId : null;

    try {
      const { data, error } = await supabase
        .from('talk_archive') // Ensure this matches your actual table name
        .insert([{
          type,
          player_id: playerIdNumber,
          Name: type === 'parent' || type === 'coach' || type === 'director' ? Name : null,
          Date,
          Resume
        }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/talk', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Select type</option>
            <option value="player">Player</option>
            <option value="parent">Parent</option>
            <option value="coach">Coach</option>
            <option value="director">Director</option>
          </select>
        </div>

        {(type === 'player' || type === 'parent') && (
          <div className="form-group">
            <label htmlFor="playerName">Player Name:</label>
            <input
              type="text"
              id="playerName"
              value={playerName}
              onChange={(e) => {
                setPlayerName(e.target.value);
                setSelectedPlayerId(null); // Reset selected player ID on name change
              }}
            />
        {(type === 'player' || type === 'parent') && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion.id}
                    onClick={() => {
                      setPlayerName(suggestion.Name);
                      setSelectedPlayerId(suggestion.id);
                      setPlayerId(suggestion.id);
                      setSuggestions([]); // Clear suggestions after selection
                    }}
                  >
                    {suggestion.Name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {(type === 'parent' || type === 'coach' || type === 'director') && (
          <div className="form-group">
            <label htmlFor="Name">Name:</label>
            <input
              type="text"
              id="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="Date">Date:</label>
          <input
            type="date"
            id="Date"
            value={Date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="Resume">Resume:</label>
          <textarea
            id="Resume"
            value={Resume}
            onChange={(e) => setResume(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button type="submit">Submit</button>
        </div>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default CreateTalkArchive;
