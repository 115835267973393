import React from 'react';

const Querie = ({ item, index }) => {
  return (
    <div className="smoothie-card">
      <h3>{item.Name}</h3>
      <p>{item.Position}</p>
      <p>{item.Birth_date}</p>
    </div>
  );
};

export default Querie;
