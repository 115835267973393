
import React, { useEffect, useState } from 'react';
import supabase from "../config/supabaseClient";
import GameDetailPage from './GameDetailPage';  // Import the GameDetailPage component
import '../index2.css';  

const ViewGames = () => {
  const [fetchError, setFetchError] = useState(null);
  const [games, setGames] = useState([]);
  const [orderBy, setOrderBy] = useState('Date');  
  const [selectedGameId, setSelectedGameId] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const { data, error } = await supabase
          .from('game')  // Ensure this matches your actual table name
          .select()
          .order(orderBy, { ascending: false });

        if (error) {
          throw error;
        }

        setGames(data);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch the games: ' + (error.message || 'Unknown error'));
        setGames([]);
      }
    };

    fetchGames();
  }, [orderBy]);

  const handleViewDetails = (id) => {
    setSelectedGameId(id);  // Set the selected game ID
  };

  const handleBack = () => {
    setSelectedGameId(null);  // Clear the selected game ID to go back
  };

  return (
    <div className="page view-games">
      {selectedGameId ? (
        <GameDetailPage gameId={selectedGameId} onBack={handleBack} />
      ) : (
        <>
          {fetchError && (<p className="error">{fetchError}</p>)}
          <div className="order-by">
            <p>Order by:</p>
            <button onClick={() => setOrderBy('Date')}>Date</button>
            <button onClick={() => setOrderBy('Time')}>Time</button>
            <button onClick={() => setOrderBy('Journey')}>Journey</button>
          </div>
          <div className="smoothie-grid">
            {games.length === 0 ? (
              <p>No games found.</p>
            ) : (
              games.map(game => (
                <div key={game.id} className="game-card">
                  <div className="game-summary">
                    <h3><strong>Journey:</strong> {game.Journey}</h3>
                    <h3><strong>Date:</strong> {game.Date}</h3>
                  </div>
                  <button className="view-details-button" onClick={() => handleViewDetails(game.id)}>
                    View Details
                  </button>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewGames;

