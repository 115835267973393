import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient'; // Ensure this is the correct path

const TestCard = ({ talk, onDelete }) => {
  
  return (
    <div className="smoothie-card">
      <h3><strong>Test Name:</strong> {talk.test_name}</h3>
    </div>
  );
};

export default TestCard;
