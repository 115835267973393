// src/pages/InternalScouting.js

import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function InternalScouting() {
  const { id } = useParams();
  const [scoutingData, setScoutingData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchScoutingData = async () => {
      try {
        // Fetch internal scouting data
        const { data, error } = await supabase
          .from('internal_scouting')
          .select('coment, Date')
          .eq('player_id', id);

        if (error) {
          throw error;
        }
        
        setScoutingData(data);
      } catch (error) {
        console.error('Error fetching scouting data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchScoutingData();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!scoutingData.length) return <div style={styles.message}>No scouting records found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Internal Scouting Records</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>Comment</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {scoutingData.map((record, index) => (
            <tr key={index}>
              <td>{record.coment}</td>
              <td>{new Date(record.Date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
  message: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#666',
  },
};

export default InternalScouting;
