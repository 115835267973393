import { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const Create = () => {
  const navigate = useNavigate();

  const [date, setdate] = useState('');
  const [journey, setjourney] = useState('');
  const [time, settime] = useState('');
  const [weather, setweather] = useState('');
  const [formError, setFormError] = useState(null);

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!date || !journey || !time || !weather) {
    setFormError('Please fill in all the fields correctly.');
    return;
  }

  try {
    const { data, error } = await supabase
      .from('practices')  // Ensure this matches your actual table name
      .insert([{ date, journey, time, weather}]);

    if (error) {
      console.error('Supabase error:', error);
      setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
    } else {
      console.log('Insert successful:', data);
      setFormError(null);
      navigate('/practices', { replace: true });
    }
  } catch (err) {
    console.error('Unexpected error:', err);
    setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
  }
};


  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="date">Date:</label>
        <input 
          type="date" 
          id="date"
          value={date}
          onChange={(e) => setdate(e.target.value)}
        />

        <label htmlFor="journey">Journey:</label>
        <textarea 
          id="journey"
          value={journey}
          onChange={(e) => setjourney(e.target.value)}
        />

        <label htmlFor="time">Time:</label>
        <input 
          type="time"
          id="time"
          value={time}
          onChange={(e) => settime(e.target.value)}
        />

        <label htmlFor="weather">Weather:</label>
        <input 
          type="text"
          id="weather"
          value={weather}
          onChange={(e) => setweather(e.target.value)}
        />

        <button>Create Practice</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create;
