/*
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const ManagePlayerStatusesPage = () => {
  const [playerStatuses, setPlayerStatuses] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = new URLSearchParams(location.search).get('gameId');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        // Check if records already exist for this game
        const { data: existingRecords, error: recordsError } = await supabase
          .from('status_player_game')
          .select('*')
          .eq('game_id', gameId);

        if (recordsError) {
          throw recordsError;
        }

        if (existingRecords.length > 0) {
          setHasRecords(true); // Set the flag if records exist
          setPlayerStatuses(existingRecords);
        } else {
          // Fetch players who are "summoned"
          const { data: summonedPlayers, error: playersError } = await supabase
            .from('call_players_game')
            .select('player_id, players (Name)')
            .eq('game_id', gameId)
            .eq('call', 'summoned');

          if (playersError) {
            throw playersError;
          }

          // Initialize statuses for summoned players
          const initialStatuses = summonedPlayers.map(({ player_id, players }) => ({
            player_id,
            game_id: gameId,
            status: '',
            position: '',
            name: players?.Name || 'Unknown'
          }));

          setPlayerStatuses(initialStatuses);
          setHasRecords(false);
        }
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch player statuses: ' + (error.message || 'Unknown error'));
      }
    };

    fetchStatuses();
  }, [gameId]);

  const handleStatusChange = (playerId, field, value) => {
    setPlayerStatuses(prevStatuses => prevStatuses.map(status =>
      status.player_id === playerId
        ? { ...status, [field]: value }
        : status
    ));
  };

  const handleAddStatuses = async () => {
    try {
      // Insert new records into the table
      const { data, error } = await supabase
        .from('status_player_game')
        .upsert(playerStatuses, { onConflict: ['player_id', 'game_id'] });

      if (error) {
        throw error;
      }

      console.log('Statuses inserted successfully:', data);
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error('Could not insert player statuses:', error.message || 'Unknown error');
      setFetchError('Could not insert player statuses: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="page manage-statuses">
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      {fetchError && <p className="error">{fetchError}</p>}
      {!hasRecords && (
        <>
          <h2>Manage Player Statuses</h2>
          <div className="status-form">
            {playerStatuses.map(({ player_id, status, position, name }) => (
              <div key={player_id} className="status-item">
                <p>Player: {name} (ID: {player_id})</p>
                <label>
                  Status:
                  <select
                    value={status || ''}
                    onChange={(e) => handleStatusChange(player_id, 'status', e.target.value)}
                  >
                    <option value="">Select status</option>
                    <option value="Summoned">Summoned</option>
                    <option value="Not Summoned">Not Summoned</option>
                  </select>
                </label>
                <label>
                  Position:
                  <input
                    type="text"
                    value={position || ''}
                    onChange={(e) => handleStatusChange(player_id, 'position', e.target.value)}
                  />
                </label>
                <hr />
              </div>
            ))}
          </div>
          <button onClick={handleAddStatuses} className="add-button">Add Statuses</button>
        </>
      )}
      {hasRecords && <p>Statuses already exist for this game.</p>}
    </div>
  );
};

export default ManagePlayerStatusesPage;
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const ManagePlayerStatusesPage = () => {
  const [playerStatuses, setPlayerStatuses] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = new URLSearchParams(location.search).get('gameId');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        // Check if records already exist for this game
        const { data: existingRecords, error: recordsError } = await supabase
          .from('status_player_game')
          .select('*')
          .eq('game_id', gameId);

        if (recordsError) {
          throw recordsError;
        }

        if (existingRecords.length > 0) {
          setHasRecords(true); // Set the flag if records exist
          setPlayerStatuses(existingRecords);
        } else {
          // Fetch players who are "summoned" without the 'name' field
          const { data: summonedPlayers, error: playersError } = await supabase
            .from('call_players_game')
            .select('player_id')
            .eq('game_id', gameId)
            .eq('call', 'summoned');

          if (playersError) {
            throw playersError;
          }

          // Initialize statuses for summoned players without 'name'
          const initialStatuses = summonedPlayers.map(({ player_id }) => ({
            player_id,
            game_id: gameId,
            status: '',
            position: ''
          }));

          setPlayerStatuses(initialStatuses);
          setHasRecords(false);
        }
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch player statuses: ' + (error.message || 'Unknown error'));
      }
    };

    fetchStatuses();
  }, [gameId]);

  const handleStatusChange = (playerId, field, value) => {
    setPlayerStatuses(prevStatuses => prevStatuses.map(status =>
      status.player_id === playerId
        ? { ...status, [field]: value }
        : status
    ));
  };

  const handleAddStatuses = async () => {
    try {
      // Insert new records into the table
      const { data, error } = await supabase
        .from('status_player_game')
        .upsert(playerStatuses, { onConflict: ['player_id', 'game_id'] });

      if (error) {
        throw error;
      }

      console.log('Statuses inserted successfully:', data);
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error('Could not insert player statuses:', error.message || 'Unknown error');
      setFetchError('Could not insert player statuses: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="page manage-statuses">
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      {fetchError && <p className="error">{fetchError}</p>}
      {!hasRecords && (
        <>
          <h2>Manage Player Statuses</h2>
          <div className="status-form">
            {playerStatuses.map(({ player_id, status, position }) => (
              <div key={player_id} className="status-item">
                <p>Player ID: {player_id}</p>
                <label>
                  Status:
                  <select
                    value={status || ''}
                    onChange={(e) => handleStatusChange(player_id, 'status', e.target.value)}
                  >
                    <option value="">Select status</option>
                    <option value="Summoned">Summoned</option>
                    <option value="Not Summoned">Not Summoned</option>
                  </select>
                </label>
                <label>
                  Position:
                  <input
                    type="text"
                    value={position || ''}
                    onChange={(e) => handleStatusChange(player_id, 'position', e.target.value)}
                  />
                </label>
                <hr />
              </div>
            ))}
          </div>
          <button onClick={handleAddStatuses} className="add-button">Add Statuses</button>
        </>
      )}
      {hasRecords && <p>Statuses already exist for this game.</p>}
    </div>
  );
};

export default ManagePlayerStatusesPage;
