import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import '../index2.css';

const InsertGameEventsPage = () => {
  const [events, setEvents] = useState([{ player_id: '', minute: '', type: '' }]);
  const [playerSuggestions, setPlayerSuggestions] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = new URLSearchParams(location.search).get('gameId');

  useEffect(() => {
    // Fetch player names for autocomplete suggestions
    const fetchPlayers = async () => {
      try {
        const { data, error } = await supabase
          .from('players')
          .select('id, Name');

        if (error) {
          throw error;
        }

        setPlayerSuggestions(data || []);
      } catch (error) {
        setFetchError('Could not fetch players: ' + (error.message || 'Unknown error'));
      }
    };

    fetchPlayers();
  }, []);

  const handleEventChange = (index, field, value) => {
    setEvents(prevEvents =>
      prevEvents.map((event, i) =>
        i === index ? { ...event, [field]: value } : event
      )
    );
  };

  const addEventRow = () => {
    setEvents([...events, { player_id: '', minute: '', type: '' }]);
  };

  const removeEventRow = (index) => {
    setEvents(events.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      const formattedEvents = events.map(event => ({
        ...event,
        game_id: gameId,
        minute: parseInt(event.minute, 10)
      }));

      const { error } = await supabase
        .from('game_event')
        .insert(formattedEvents);

      if (error) {
        throw error;
      }

      console.log('Events inserted successfully');
      navigate(-1); // Navigate back after successful insert
    } catch (error) {
      console.error('Could not insert events:', error.message || 'Unknown error');
      setFetchError('Could not insert events: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="page insert-game-events">
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      {fetchError && <p className="error">{fetchError}</p>}
      <h2>Insert Game Events</h2>
      <div className="events-form">
        {events.map((event, index) => (
          <div key={index} className="event-item">
            <label>
              Player:
              <input
                type="text"
                list={`player-suggestions-${index}`}
                value={event.player_id}
                onChange={(e) => handleEventChange(index, 'player_id', e.target.value)}
                placeholder="Start typing player name..."
              />
              <datalist id={`player-suggestions-${index}`}>
                {playerSuggestions.map(player => (
                  <option key={player.id} value={player.id}>
                    {player.Name}
                  </option>
                ))}
              </datalist>
            </label>
            <label>
              Minute:
              <input
                type="number"
                value={event.minute}
                onChange={(e) => handleEventChange(index, 'minute', e.target.value)}
                placeholder="Minute"
              />
            </label>
            <label>
              Type:
              <select
                value={event.type}
                onChange={(e) => handleEventChange(index, 'type', e.target.value)}
              >
                <option value="">Select type</option>
                <option value="yellow card">Yellow Card</option>
                <option value="red card">Red Card</option>
                <option value="goal">Goal</option>
              </select>
            </label>
            <button onClick={() => removeEventRow(index)} className="remove-button">Remove</button>
            <hr />
          </div>
        ))}
      </div>
      <button onClick={addEventRow} className="add-button">Add Another Event</button>
      <button onClick={handleSubmit} className="submit-button">Insert Events</button>
    </div>
  );
};

export default InsertGameEventsPage;
