import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"

import SmoothieCard from '../components/SmoothieCard'


const View = () => {

  const [fetchError, setFetchError] = useState(null)
  const [smoothies, setSmoothies] = useState(null)
  const [orderBy, setOrderBy] = useState('created_at')


  const handleDelete = (id) => {
    setSmoothies(prevSmoothies => {
      return prevSmoothies.filter(sm => sm.id !== id)
    })
  }

  useEffect(() => {
    const fetchSmoothies = async () => {
      const { data, error } = await supabase
        .from('players')
        .select()
        .order(orderBy, {ascending: false})

      
      if (error) {
        setFetchError('Could not fetch the players')
        setSmoothies(null)
      }
      if (data) {
        setSmoothies(data)
        setFetchError(null)
      }
    }

    fetchSmoothies()

  }, [orderBy])

  return (
    <div className="page home">
      {fetchError && (<p>{fetchError}</p>)}
      {smoothies && (
        <div className="smoothies">
            <div className="order-by">
              <p>Order by:</p>
              <button onClick={() => setOrderBy('Name')}>Name</button>
              <button onClick={() => setOrderBy('Position')}>Position</button>
              <button onClick={() => setOrderBy('Birth_date')}>Birth Date</button>
            </div>
            <div className="smoothie-grid">
            {smoothies.map(Players => (
              <SmoothieCard key={Players.id} Players={Players} onDelete={handleDelete}/>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default View