/*
import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function MedicalReports() {
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchMedicalReports = async () => {
      try {
        const { data, error } = await supabase
          .from('medical_report')
          .select('report, stop_time')
          .eq('player_id', id);

        if (error) {
          throw error;
        }

        setReports(data);
      } catch (error) {
        console.error('Error fetching medical reports:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMedicalReports();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!reports.length) return <div style={styles.error}>No medical reports found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Medical Reports</h2>
      <div style={styles.reportList}>
        {reports.map((report, index) => (
          <div key={index} style={styles.report}>
            <p><strong>Report:</strong> {report.report}</p>
            <p><strong>Stop Time:</strong> {new Date(report.stop_time).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  reportList: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  report: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default MedicalReports;
*/

// src/pages/MedicalReports.js

import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function MedicalReports() {
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchMedicalReports = async () => {
      try {
        const { data, error } = await supabase
          .from('medical_report')
          .select('report, stop_time, date')
          .eq('player_id', id);

        if (error) {
          throw error;
        }

        setReports(data);
      } catch (error) {
        console.error('Error fetching medical reports:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMedicalReports();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!reports.length) return <div style={styles.error}>No medical reports found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Medical Reports</h2>
      <div style={styles.reportList}>
        {reports.map((report, index) => (
          <div key={index} style={styles.report}>
            <p><strong>Report:</strong> {report.report}</p>
            <p><strong>Stop Time:</strong> {report.stop_time}</p>
            <p><strong>Date:</strong> {report.date}</p>

          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  reportList: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  report: {
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default MedicalReports;
