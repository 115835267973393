import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import supabase from "../config/supabaseClient";

function GameVideo() {
  const [videoUrl, setVideoUrl] = useState('');
  const [gameVideos, setGameVideos] = useState([]);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const playerRef = useRef(null);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      disablekb: 1,
    },
  };

  // Fetch game videos from Supabase
  useEffect(() => {
    const fetchGameVideos = async () => {
      const { data, error } = await supabase
        .from('game_video')
        .select('game_id, video_url');
      
      if (error) console.error('Error fetching videos:', error);
      else setGameVideos(data);
    };

    fetchGameVideos();
  }, []);

  // Set selected game video
  const handleSelectGame = (gameId) => {
    const selectedVideo = gameVideos.find(video => video.game_id === parseInt(gameId, 10));
    if (selectedVideo) {
      setVideoUrl(selectedVideo.video_url);
      setSelectedGameId(gameId);
    }
  };

  // Extract video ID from the YouTube URL
  const getVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      // Check if the URL is in the form https://www.youtube.com/watch?v=VIDEO_ID
      if (urlObj.hostname.includes('youtube.com') && urlObj.searchParams.has('v')) {
        return urlObj.searchParams.get('v');
      }
      // Check if the URL is in the form https://youtu.be/VIDEO_ID
      if (urlObj.hostname.includes('youtu.be')) {
        return urlObj.pathname.slice(1);
      }
      return null;  // Return null if it doesn't match any expected format
    } catch (error) {
      console.error("Invalid URL format:", url);
      return null;
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const skipToTenMinutes = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(600);
      playerRef.current.pauseVideo();
    }
  };

  // Inline CSS styles
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  };

  const headerStyle = {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333',
  };

  const videoContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const dropdownStyle = {
    padding: '10px',
    fontSize: '1rem',
    marginBottom: '20px',
  };

  return (
    <div style={appStyle}>
      <h1 style={headerStyle}>YouTube Video Visualizer</h1>
      
      <select 
        style={dropdownStyle} 
        onChange={(e) => handleSelectGame(e.target.value)}
        value={selectedGameId || ''}
      >
        <option value="">Select Game</option>
        {gameVideos.map((video) => (
          <option key={video.game_id} value={video.game_id}>
            Game {video.game_id}
          </option>
        ))}
      </select>

      {videoUrl && (
        <div style={videoContainerStyle}>
          <YouTube videoId={getVideoId(videoUrl)} opts={opts} onReady={onReady} />
        </div>
      )}

      {videoUrl && (
        <button style={buttonStyle} onClick={skipToTenMinutes}>
          Skip to 10 Minutes (Paused)
        </button>
      )}
    </div>
  );
}

export default GameVideo;
