import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../CreateTalkArchive.css'; // Import the CSS file

const Create_game = () => {
  const navigate = useNavigate();

  const [Journey, setJourney] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [weather, setWeather] = useState('');
  const [temperature, setTemperature] = useState('');
  const [adversaryName, setAdversaryName] = useState('');
  const [formError, setFormError] = useState(null);
  const [adversaries, setAdversaries] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAdversaryId, setSelectedAdversaryId] = useState(null);

  useEffect(() => {
    const fetchAdversaries = async () => {
      const { data, error } = await supabase
        .from('adversaries')
        .select('id, Adversarie');
      
      if (error) {
        console.error('Error fetching adversaries:', error);
        return;
      }
      setAdversaries(data || []);
    };

    fetchAdversaries();
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (adversaryName) {
        const { data, error } = await supabase
          .from('adversaries')
          .select('id, Adversarie')
          .ilike('Adversarie', `%${adversaryName}%`); // Case-insensitive search

        if (error) {
          console.error('Error fetching adversary suggestions:', error);
          return;
        }
        setSuggestions(data || []);
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [adversaryName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!Journey || !date || !time || !weather || !temperature || !selectedAdversaryId) {
      setFormError('Please fill in all required fields.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('game')
        .insert([{
          Journey,
          Date: date,
          Time: time,
          Weather: weather,
          Temperature: temperature,
          Adversarie_id: selectedAdversaryId
        }]);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error inserting the data. ' + (error.message || 'Unknown error'));
      } else {
        console.log('Insert successful:', data);
        setFormError(null);
        navigate('/scouting', { replace: true });
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred. ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="Journey">Journey:</label>
          <input
            type="text"
            id="Journey"
            value={Journey}
            onChange={(e) => setJourney(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="time">Time:</label>
          <input
            type="time"
            id="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="weather">Weather:</label>
          <input
            type="text"
            id="weather"
            value={weather}
            onChange={(e) => setWeather(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="temperature">Temperature:</label>
          <input
            type="number"
            id="temperature"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="adversary">Adversary:</label>
          <input
            type="text"
            id="adversary"
            value={adversaryName}
            onChange={(e) => {
              setAdversaryName(e.target.value);
              setSelectedAdversaryId(null); // Reset selected adversary ID on name change
            }}
          />
          <ul className="suggestions-list">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                onClick={() => {
                  setAdversaryName(suggestion.Adversarie);
                  setSelectedAdversaryId(suggestion.id);
                  setSuggestions([]); // Clear suggestions after selection
                }}
              >
                {suggestion.Adversarie}
              </li>
            ))}
          </ul>
        </div>

        <div className="form-group">
          <button type="submit">Submit</button>
        </div>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Create_game;