
import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // Import the autotable plugin

function PlayerDetails() {
  const { id } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [player, setPlayer] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [summonCount, setSummonCount] = useState(0);
  const [notSummonedCount, setNotSummonedCount] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [firstStatusCount, setFirstStatusCount] = useState(0);
  const [benchStatusCount, setBenchStatusCount] = useState(0);
  const [yellowCardCount, setYellowCardCount] = useState(0);
  const [redCardCount, setRedCardCount] = useState(0);
  const [goalCount, setGoalCount] = useState(0);
  const [presentCount, setPresentCount] = useState(0);
  const [medicalDepartmentCount, setMedicalDepartmentCount] = useState(0);
  const [justifiedAbsenceCount, setJustifiedAbsenceCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchPlayerDetails = async () => {
      try {
        // Fetch player details with additional fields
        const { data: playerData, error: playerError } = await supabase
          .from('players')
          .select('id, Name, Birth_date, Number, Phone_Number, Zone, Position')
          .eq('id', id)
          .single();

        if (playerError) {
          throw playerError;
        }

        if (!playerData) {
          throw new Error('No player found');
        }

        setPlayer(playerData);

        // Fetch summon count where call is "summoned"
        const { count: summonedCount, error: summonError } = await supabase
          .from('call_players_game')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('call', 'summoned');

        if (summonError) {
          throw summonError;
        }

        setSummonCount(summonedCount);

        // Fetch not summoned count where call is "not_summoned"
        const { count: notSummonedCount, error: notSummonError } = await supabase
          .from('call_players_game')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('call', 'not_summoned');

        if (notSummonError) {
          throw notSummonError;
        }

        setNotSummonedCount(notSummonedCount);

        // Fetch total minutes played from game_minutes table
        const { data: totalMinutesData, error: minutesError } = await supabase
          .from('game_minutes')
          .select('minutes')
          .eq('player_id', id);

        if (minutesError) {
          throw minutesError;
        }

        const totalMinutesSum = totalMinutesData.reduce(
          (acc, curr) => acc + curr.minutes,
          0
        );
        setTotalMinutes(totalMinutesSum);

        // Fetch first status count from status_player_game table
        const { count: firstStatusCount, error: firstStatusError } = await supabase
          .from('status_player_game')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'first');

        if (firstStatusError) {
          throw firstStatusError;
        }

        setFirstStatusCount(firstStatusCount);

        // Fetch bench status count from status_player_game table
        const { count: benchStatusCount, error: benchStatusError } = await supabase
          .from('status_player_game')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'bench');

        if (benchStatusError) {
          throw benchStatusError;
        }

        setBenchStatusCount(benchStatusCount);

        // Fetch yellow card count from game_event table
        const { count: yellowCardCount, error: yellowCardError } = await supabase
          .from('game_event')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('type', 'yellow card');

        if (yellowCardError) {
          throw yellowCardError;
        }

        setYellowCardCount(yellowCardCount);

        // Fetch red card count from game_event table
        const { count: redCardCount, error: redCardError } = await supabase
          .from('game_event')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('type', 'red card');

        if (redCardError) {
          throw redCardError;
        }

        setRedCardCount(redCardCount);

        // Fetch goal count from game_event table
        const { count: goalCount, error: goalError } = await supabase
          .from('game_event')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('type', 'goal');

        if (goalError) {
          throw goalError;
        }

        setGoalCount(goalCount);

        // Fetch presence counts
        const { count: presentCount, error: presentError } = await supabase
          .from('practices_presence')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'Present');

        if (presentError) {
          throw presentError;
        }

        setPresentCount(presentCount);

        const { count: medicalDepartmentCount, error: medicalDeptError } = await supabase
          .from('practices_presence')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'Medical Department');

        if (medicalDeptError) {
          throw medicalDeptError;
        }

        setMedicalDepartmentCount(medicalDepartmentCount);

        const { count: justifiedAbsenceCount, error: justifiedAbsenceError } = await supabase
          .from('practices_presence')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'Justified Absence');

        if (justifiedAbsenceError) {
          throw justifiedAbsenceError;
        }

        setJustifiedAbsenceCount(justifiedAbsenceCount);

        const { count: absenceCount, error: absenceError } = await supabase
          .from('practices_presence')
          .select('id', { count: 'exact' })
          .eq('player_id', id)
          .eq('status', 'Absence');

        if (absenceError) {
          throw absenceError;
        }

        setAbsenceCount(absenceCount);

        // Fetch average rating
        const { data: ratingsData, error: ratingsError } = await supabase
          .from('game_ratings')
          .select('rating')
          .eq('player_id', id);

        if (ratingsError) {
          throw ratingsError;
        }

        if (ratingsData.length > 0) {
          const average = ratingsData.reduce((acc, curr) => acc + curr.rating, 0) / ratingsData.length;
          setAverageRating(average.toFixed(2));
        } else {
          setAverageRating(0);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerDetails();
  }, [id]);

  const handleViewMedicalReports = () => {
    navigate(`/medical-reports/${id}`);
  };

  const handleViewGameEvents = () => {
    navigate(`/game-events/${id}`);
  };

  const handleViewTestRecords = () => {
    navigate(`/test-records/${id}`);
  };

  const handleViewAllPracticesPresence = () => {
    navigate(`/all-practices-presence/${id}`);
  };

  const handleViewInternalScouting = () => {
    navigate(`/internal-scouting/${id}`);
  };

  const handleViewTalkArchive = () => {
    navigate(`/talk-archive/${id}`);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(24);
    doc.text('Player Details Report', 105, 20, null, null, 'center');

    // Player details
    doc.setFontSize(12);
    doc.text(`Name: ${player.Name}`, 20, 40);
    doc.text(`Birth Date: ${player.Birth_date}`, 20, 50);
    doc.text(`Number: ${player.Number}`, 20, 60);
    doc.text(`Phone Number: ${player.Phone_Number}`, 20, 70);
    doc.text(`Zone: ${player.Zone}`, 20, 80);
    doc.text(`Position: ${player.Position}`, 20, 90);
    
    doc.autoTable({
      startY: 100,
      head: [['Stat', 'Value']],
      body: [
        ['Summon Count', summonCount],
        ['Not Summoned Count', notSummonedCount],
        ['Total Minutes Played', totalMinutes],
        ['First Team Count', firstStatusCount],
        ['Bench Count', benchStatusCount],
        ['Yellow Cards', yellowCardCount],
        ['Red Cards', redCardCount],
        ['Goals', goalCount],
        ['Present Count', presentCount],
        ['Medical Department Count', medicalDepartmentCount],
        ['Justified Absence Count', justifiedAbsenceCount],
        ['Absence Count', absenceCount],
        ['Average Rating', averageRating],
      ],
      styles: { fillColor: [220, 220, 220] },
      headStyles: { fillColor: [12, 188, 162] },
    });


    doc.setFontSize(10);
    doc.text('Generated by FootBased', 105, doc.internal.pageSize.height - 10, null, null, 'center');


    // Save the PDF
    doc.save(`Player_Details_${player.Name}.pdf`);
  };

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!player) return <div style={styles.error}>No player found.</div>;


  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Player Details</h2>
      <div style={styles.detail}>
        <p><strong>Name:</strong> {player.Name}</p>
        <p><strong>Birth Date:</strong> {player.Birth_date}</p>
        <p><strong>Number:</strong> {player.Number}</p>
        <p><strong>Phone Number:</strong> {player.Phone_Number}</p>
        <p><strong>Zone:</strong> {player.Zone}</p>
        <p><strong>Position:</strong> {player.Position}</p>
        <p><strong>Summon Count:</strong> {summonCount}</p>
        <p><strong>Not Summoned Count:</strong> {notSummonedCount}</p>
        <p><strong>Total Minutes Played:</strong> {totalMinutes}</p>
        <p><strong>First Team Count:</strong> {firstStatusCount}</p>
        <p><strong>Bench Count:</strong> {benchStatusCount}</p>
        <p><strong>Yellow Cards:</strong> {yellowCardCount}</p>
        <p><strong>Red Cards:</strong> {redCardCount}</p>
        <p><strong>Goals:</strong> {goalCount}</p>
        <p><strong>Present Count:</strong> {presentCount}</p>
        <p><strong>Medical Department Count:</strong> {medicalDepartmentCount}</p>
        <p><strong>Justified Absence Count:</strong> {justifiedAbsenceCount}</p>
        <p><strong>Absence Count:</strong> {absenceCount}</p>
        <p><strong>Average Rating:</strong> {averageRating}</p>
        <button style={styles.button} onClick={handleViewMedicalReports}>View Medical Reports</button>
        <button style={styles.button} onClick={handleViewGameEvents}>View Game Events</button>
        <button style={styles.button} onClick={handleViewTestRecords}>View Test Records</button>
        <button onClick={handleViewAllPracticesPresence} style={styles.button}>View All Practices Presence</button>
        <button style={styles.button} onClick={handleViewInternalScouting}>View Internal Scouting Records</button>
        <button style={styles.button} onClick={handleViewTalkArchive}>View Talk Archive</button>
        <button style={styles.button} onClick={handleGeneratePDF}>Generate PDF</button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  detail: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#12bca2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default PlayerDetails;
