import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import your CSS file if you have one

const Practices = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Practices</h1>
        <p>Select a page to visit:</p>
      </header>
      <main className="home-content">
        <nav className="home-nav">
          <ul>
            <li>
              <Link to="/create_practice">Create an Practice</Link>
            </li>
            <li>
              <Link to="/view_practices">View Practices</Link>
            </li>
            <li>
              <Link to="/Practice-presence">Insert Presences</Link>
            </li>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/">Log-out</Link>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

export default Practices;
