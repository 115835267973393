import React, { useEffect, useState } from 'react'
import supabase from '../config/supabaseClient'
import Querie_report from '../components/Querie_report'
import '../index.css' // Adjust the path according to your project structure
import '../querie_report.css'

function Filter_report() {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [nameValue, setNameValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [selectedName, setSelectedName] = useState('')

  // Fetch matching names for autocomplete
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (nameValue.trim() === '') {
        setSuggestions([])
        return
      }

      const { data: results, error } = await supabase
        .from('players')
        .select('Name')
        .ilike('Name', `%${nameValue}%`)

      if (error) {
        console.error(error)
        setError(error)
      } else {
        setSuggestions(results || [])
      }
    }

    fetchSuggestions()
  }, [nameValue])

  // Fetch data based on selected name
  useEffect(() => {
    const fetchData = async () => {
      if (selectedName.trim() === '') {
        setData([])
        return
      }

      const { data: results, error } = await supabase.rpc('get_player_medical_report', { p_name: selectedName })
      if (error) {
        console.error(error)
        setError(error)
      } else {
        setData(results)
      }
    }

    fetchData()
  }, [selectedName])

  const handleInputChange = (e) => {
    setNameValue(e.target.value)
  }

  const handleSuggestionClick = (name) => {
    setSelectedName(name)
    setNameValue(name) // Optional: update input value with selected name
    setSuggestions([]) // Clear suggestions after selection
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className="page">
      <h2>Insert the Name</h2>
      <input
        type="text"
        value={nameValue}
        onChange={handleInputChange}
        placeholder="Enter Name value"
      />
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion) => (
            <li key={suggestion.Name} onClick={() => handleSuggestionClick(suggestion.Name)}>
              {suggestion.Name}
            </li>
          ))}
        </ul>
      )}
      <div className="querie-grid">
        {data.length > 0 ? (
          data.map((item, index) => (
            <Querie_report key={index} item={item} />
          ))
        ) : (
          <p>No reports found</p>
        )}
      </div>
    </div>
  )
}

export default Filter_report
