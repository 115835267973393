import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from '../config/supabaseClient';
import '../index2.css';

const InsertGameMinutesPage = () => {
  const [players, setPlayers] = useState([]);
  const [minutesData, setMinutesData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = new URLSearchParams(location.search).get('gameId');

  useEffect(() => {
    const fetchSummonedPlayers = async () => {
      try {
        const { data, error } = await supabase
          .from('call_players_game')
          .select('player_id, players (Name)')
          .eq('game_id', gameId)
          .eq('call', 'summoned');

        if (error) {
          throw error;
        }

        // Initialize minutes data for each player
        const initialMinutesData = data.map(({ player_id }) => ({
          player_id,
          game_id: gameId,
          minutes: 0,
        }));

        setPlayers(data);
        setMinutesData(initialMinutesData);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch summoned players: ' + (error.message || 'Unknown error'));
      }
    };

    fetchSummonedPlayers();
  }, [gameId]);

  const handleMinutesChange = (playerId, value) => {
    setMinutesData(prevData =>
      prevData.map(player =>
        player.player_id === playerId ? { ...player, minutes: parseInt(value, 10) || 0 } : player
      )
    );
  };

  const handleSubmit = async () => {
    try {
      const { data, error } = await supabase
        .from('game_minutes')
        .insert(minutesData);

      if (error) {
        throw error;
      }

      console.log('Minutes inserted successfully:', data);
      navigate(-1); // Navigate back to the previous page after submission
    } catch (error) {
      console.error('Could not insert game minutes:', error.message || 'Unknown error');
      setSubmitError('Could not insert game minutes: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="page insert-game-minutes">
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      {fetchError && <p className="error">{fetchError}</p>}
      <h2>Insert Game Minutes</h2>
      <div className="minutes-form">
        {players.map(({ player_id, players }) => (
          <div key={player_id} className="minutes-item">
            <p>Player: {players?.Name || 'Unknown'} (ID: {player_id})</p>
            <label>
              Minutes Played:
              <input
                type="number"
                value={minutesData.find(player => player.player_id === player_id)?.minutes || 0}
                onChange={(e) => handleMinutesChange(player_id, e.target.value)}
                min="0"
              />
            </label>
            <hr />
          </div>
        ))}
      </div>
      <button onClick={handleSubmit} className="submit-button">Submit Minutes</button>
      {submitError && <p className="error">{submitError}</p>}
    </div>
  );
};

export default InsertGameMinutesPage;
