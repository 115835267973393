import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import InternalScoutingCard from '../components/InternalScoutingCard';

const View_Internal_Scouting = () => {
  const [talks, setTalks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at');

  // Fetch talks from Supabase
  useEffect(() => {
    const fetchTalks = async () => {
      try {
        const { data, error } = await supabase
          .from('internal_scouting')
          .select('*')
          .order(orderBy, { ascending: false });

        if (error) throw error;
        setTalks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTalks();
  }, [orderBy]);

  // Handle delete action
  const handleDelete = (id) => {
    setTalks(prevTalks => prevTalks.filter(talk => talk.id !== id));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="page home">
      <div className="order-by">
        <p>Order by:</p>
        <button onClick={() => setOrderBy('type')}>Type</button>
        <button onClick={() => setOrderBy('player_id')}>Player ID</button>
        <button onClick={() => setOrderBy('Date')}>Date</button>
      </div>
      <div className="smoothie-grid">
        {talks.length === 0 ? (
          <p>No records found.</p>
        ) : (
          talks.map(talk => (
            <InternalScoutingCard key={talk.id} talk={talk} onDelete={handleDelete} />
          ))
        )}
      </div>
    </div>
  );
};

export default View_Internal_Scouting;
