/*
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import supabase from "../config/supabaseClient";

const ExerciseDetails = () => {
  const { id } = useParams(); // Get the ID from the URL parameters
  const [exercise, setExercise] = useState(null); // State to store the exercise details
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  // Fetch the details of the specific exercise by ID
  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises') // Your table name
          .select('*') // Select all columns
          .eq('id', id) // Filter by the exercise ID
          .single(); // Expect a single record

        if (error) {
          throw error;
        }

        setExercise(data); // Set the exercise details to the state
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExerciseDetails();
  }, [id]); // Dependency array includes `id` to refetch if the ID changes

  return (
    <div className="page exercise-details">
      <h1>Exercise Details</h1>
      
      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      {exercise && (
        <div className="exercise-details-container">
          <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
          <p><strong>Players Number:</strong> {exercise.players_number}</p>
          <p><strong>Material:</strong> {exercise.material}</p>
          <p><strong>Resume:</strong> {exercise.resume}</p>
          <p><strong>Description:</strong> {exercise.description}</p>
        </div>
      )}

      {!loading && !exercise && <p>Exercise not found.</p>}
    </div>
  );
};

export default ExerciseDetails;
*/
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import supabase from "../config/supabaseClient";

const ExerciseDetails = () => {
  const { id } = useParams();
  const [exercise, setExercise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises')
          .select('*')
          .eq('id', id)
          .single();

        if (error) {
          throw error;
        }

        setExercise(data);
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExerciseDetails();
  }, [id]);

  return (
    <div className="page exercise-details">
      <style>{`
        .page {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        h1 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        .exercise-details-container {
          max-width: 600px;
          margin: 0 auto;
          background-color: #f9f9f9;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
        .exercise-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          margin: 10px 0;
          color: #555;
        }
        p strong {
          color: #333;
        }
      `}</style>

      <h1>Exercise Details</h1>
      
      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      {exercise && (
        <div className="exercise-details-container">
          <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
          <p><strong>Players Number:</strong> {exercise.players_number}</p>
          <p><strong>Material:</strong> {exercise.material}</p>
          <p><strong>Resume:</strong> {exercise.resume}</p>
          <p><strong>Description:</strong> {exercise.description}</p>
        </div>
      )}

      {!loading && !exercise && <p>Exercise not found.</p>}
    </div>
  );
};

export default ExerciseDetails;
