/*
import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function AllPracticesPresence() {
  const { id } = useParams();
  const [practicesPresence, setPracticesPresence] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchPracticesPresence = async () => {
      try {
        // Fetch practices presence
        const { data: presenceData, error: presenceError } = await supabase
          .from('practices_presence')
          .select('practice_id, status')
          .eq('player_id', id);

        if (presenceError) {
          throw presenceError;
        }

        // Fetch practices for corresponding practice_ids
        const practiceIds = presenceData.map(record => record.practice_id);
        const { data: practicesData, error: practicesError } = await supabase
          .from('practices')
          .select('id, date')
          .in('id', practiceIds);

        if (practicesError) {
          throw practicesError;
        }

        // Map practice dates to presence records
        const practicesMap = practicesData.reduce((acc, practice) => {
          acc[practice.id] = practice.date;
          return acc;
        }, {});

        const practicesWithDates = presenceData.map(record => ({
          ...record,
          date: practicesMap[record.practice_id] || 'Date not found',
        }));

        setPracticesPresence(practicesWithDates);
      } catch (error) {
        console.error('Error fetching practices presence:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPracticesPresence();
  }, [id]);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error.message}</div>;
  if (!practicesPresence.length) return <div style={styles.error}>No practices presence records found.</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>All Practices Presence</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {practicesPresence.map(record => (
            <tr key={record.practice_id}>
              <td>{new Date(record.date).toLocaleDateString()}</td>
              <td>{record.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
};

export default AllPracticesPresence;
*/
// src/pages/AllPracticesPresence.js

import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { useParams } from 'react-router-dom';

function AllPracticesPresence() {
  const { id } = useParams();
  const [practicesPresence, setPracticesPresence] = useState([]);
  const [filteredPractices, setFilteredPractices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    if (!id || isNaN(id)) {
      setError(new Error("Invalid Player ID"));
      setLoading(false);
      return;
    }

    const fetchPracticesPresence = async () => {
      try {
        // Fetch practices presence
        const { data: presenceData, error: presenceError } = await supabase
          .from('practices_presence')
          .select('practice_id, status')
          .eq('player_id', id);

        if (presenceError) {
          throw presenceError;
        }

        // Fetch practices for corresponding practice_ids
        const practiceIds = presenceData.map(record => record.practice_id);
        const { data: practicesData, error: practicesError } = await supabase
          .from('practices')
          .select('id, date')
          .in('id', practiceIds);

        if (practicesError) {
          throw practicesError;
        }

        // Map practice dates to presence records
        const practicesMap = practicesData.reduce((acc, practice) => {
          acc[practice.id] = practice.date;
          return acc;
        }, {});

        const practicesWithDates = presenceData.map(record => ({
          ...record,
          date: practicesMap[record.practice_id] || 'Date not found',
        }));

        setPracticesPresence(practicesWithDates);
        setFilteredPractices(practicesWithDates);
      } catch (error) {
        console.error('Error fetching practices presence:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPracticesPresence();
  }, [id]);

  useEffect(() => {
    // Filter practices based on selected filter
    if (filter === 'all') {
      setFilteredPractices(practicesPresence);
    } else {
      setFilteredPractices(practicesPresence.filter(record => record.status === filter));
    }
  }, [filter, practicesPresence]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>All Practices Presence</h2>
      
      {/* Filter Box */}
      <div style={styles.filterBox}>
        <label htmlFor="filter">Filter by Status:</label>
        <select id="filter" value={filter} onChange={handleFilterChange} style={styles.select}>
          <option value="all">Display All</option>
          <option value="Present">Present</option>
          <option value="Medical Department">Medical Department</option>
          <option value="Justified Absence">Justified Absence</option>
          <option value="Absence">Absence</option>
        </select>
      </div>

      {loading ? (
        <div style={styles.loading}>Loading...</div>
      ) : error ? (
        <div style={styles.error}>Error: {error.message}</div>
      ) : filteredPractices.length === 0 ? (
        <div style={styles.message}>No practices presence records found.</div>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredPractices.map(record => (
              <tr key={record.practice_id}>
                <td>{new Date(record.date).toLocaleDateString()}</td>
                <td>{record.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  filterBox: {
    marginBottom: '20px',
  },
  select: {
    marginLeft: '10px',
    padding: '5px',
    fontSize: '16px',
  },
  loading: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#888',
  },
  error: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'red',
  },
  message: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#666',
  },
};

export default AllPracticesPresence;
