/*
import React, { useState, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';

const ffmpeg = new FFmpeg(); // Create an instance of FFmpeg

function App() {
  const [ready, setReady] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [webpUrl, setWebpUrl] = useState('');
  const [downloadLinkWebP, setDownloadLinkWebP] = useState('');
  const [mp4Url, setMp4Url] = useState('');
  const [downloadLinkMP4, setDownloadLinkMP4] = useState('');
  const [progress, setProgress] = useState(0); // State for conversion progress
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        console.log('Starting FFmpeg load...');
        await ffmpeg.load();
        console.log('FFmpeg successfully loaded.');
        setReady(true);
      } catch (error) {
        console.error('Error loading FFmpeg:', error);
      }
    };

    loadFFmpeg();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.item(0);
    if (selectedFile) {
      console.log('File selected:', selectedFile);
      setFile(selectedFile);
      setFileType(selectedFile.type);
    } else {
      console.error('No file selected.');
    }
  };

  const updateProgress = (log) => {
    const match = log.match(/time=(\d+:\d+:\d+\.\d+)/);
    if (match) {
      const time = match[1];
      // Calculate progress based on the time
      // This is a rough estimate; you might need to refine this based on your needs
      const timeParts = time.split(':').map(Number);
      const seconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
      // Assuming a fixed duration for simplicity, adjust as needed
      const duration = 100; // For example, if you have a 100-second video
      const progressPercent = (seconds / duration) * 100;
      setProgress(Math.min(100, progressPercent));
    }
  };

  const convertToWebP = async () => {
    console.log('Starting WebP conversion...');
    if (!file || !fileType.startsWith('image/')) {
      console.error('No image selected or file is not an image.');
      return;
    }

    setLoading(true);
    setProgress(0);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      await ffmpeg.writeFile('input.png', uint8Array);
      
      // Run the FFmpeg command using ffmpeg.exec
      await ffmpeg.exec(['-i', 'input.png', '-c:v', 'libwebp', '-q:v', '80', 'output.webp']);
      console.log('WebP conversion executed.');
      setProgress(100);

      const resultWebP = await ffmpeg.readFile('output.webp');
      console.log('WebP file read from FFmpeg.');

      // Use Blob to handle binary data properly
      const blobWebP = new Blob([resultWebP.buffer], { type: 'image/webp' });
      const urlWebP = URL.createObjectURL(blobWebP);
      setWebpUrl(urlWebP);
      setDownloadLinkWebP(urlWebP);
    } catch (error) {
      console.error('Error during WebP conversion:', error);
    } finally {
      setLoading(false);
    }
  };

  const convertToMP4 = async () => {
    console.log('Starting MP4 conversion...');
    if (!file || !fileType.startsWith('video/')) {
      console.error('No video selected or file is not a video.');
      return;
    }

    setLoading(true);
    setProgress(0);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const outputFileName = 'output.mp4';
      await ffmpeg.writeFile('input', uint8Array);
      
      // Run the FFmpeg command using ffmpeg.exec
      await ffmpeg.exec(['-i', 'input', '-c:v', 'libx265', '-x265-params', 'crf=28', outputFileName]);
      console.log('MP4 conversion executed.');
      setProgress(100);

      const resultMP4 = await ffmpeg.readFile(outputFileName);
      console.log('MP4 file read from FFmpeg.');

      // Use Blob to handle binary data properly
      const blobMP4 = new Blob([resultMP4.buffer], { type: 'video/mp4' });
      const urlMP4 = URL.createObjectURL(blobMP4);
      setMp4Url(urlMP4);
      setDownloadLinkMP4(urlMP4);
    } catch (error) {
      console.error('Error during MP4 conversion:', error);
    } finally {
      setLoading(false);
    }
  };

  return ready ? (
    <div className="App">
      <header className="App-header">
        <h1>Convert File</h1>
      </header>

      <main className="App-content">
        <input type="file" accept="image/*,video/*" onChange={handleFileChange} />

        {}
        {fileType.startsWith('image/') && (
          <div>
            <button onClick={convertToWebP} disabled={loading}>Convert to WebP</button>
            {loading && <p>Progress: {progress}%</p>}
            {webpUrl && (
              <div>
                <h3>Converted WebP Image:</h3>
                <img src={webpUrl} alt="Converted to WebP" width="250" />
                <br />
                <a href={downloadLinkWebP} download="converted-image.webp">
                  <button>Download WebP Image</button>
                </a>
              </div>
            )}
          </div>
        )}

        {}
        {fileType.startsWith('video/') && (
          <div>
            <button onClick={convertToMP4} disabled={loading}>Convert to MP4 (H.265)</button>
            {loading && <p>Progress: {progress}%</p>}
            {mp4Url && (
              <div>
                <h3>Converted MP4 Video:</h3>
                <video controls width="250" src={mp4Url}></video>
                <br />
                <a href={downloadLinkMP4} download="converted-video.mp4">
                  <button>Download MP4 Video</button>
                </a>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  ) : (
    <p>Loading...</p>
  );
}

export default App;
*/

import React, { useState, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';

const ffmpeg = new FFmpeg(); // Create an instance of FFmpeg

function App() {
  const [ready, setReady] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [webpUrl, setWebpUrl] = useState('');
  const [downloadLinkWebP, setDownloadLinkWebP] = useState('');
  const [mp4Url, setMp4Url] = useState('');
  const [downloadLinkMP4, setDownloadLinkMP4] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        console.log('Loading FFmpeg...');
        await ffmpeg.load();
        console.log('FFmpeg loaded.');
        setReady(true);
      } catch (error) {
        console.error('Error loading FFmpeg:', error);
      }
    };

    loadFFmpeg();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.item(0);
    if (selectedFile) {
      console.log('File selected:', selectedFile);
      setFile(selectedFile);
      setFileType(selectedFile.type);
    } else {
      console.error('No file selected.');
    }
  };

  const convertToWebP = async () => {
    if (!file || !fileType.startsWith('image/')) {
      console.error('No image selected or file is not an image.');
      return;
    }

    setLoading(true);
    setProgress(0);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      await ffmpeg.writeFile('input.png', uint8Array);

      // Run the conversion command
      const { data } = await ffmpeg.exec(['-i', 'input.png', '-c:v', 'libwebp', '-q:v', '80', 'output.webp']);

      // Simulate progress update
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        if (simulatedProgress < 100) {
          simulatedProgress += 10;
          setProgress(simulatedProgress);
        } else {
          clearInterval(interval);
        }
      }, 500);

      // Read the result from FFmpeg's virtual filesystem
      const resultWebP = await ffmpeg.readFile('output.webp');
      console.log('WebP file read from FFmpeg.');

      const blobWebP = new Blob([resultWebP.buffer], { type: 'image/webp' });
      const urlWebP = URL.createObjectURL(blobWebP);
      setWebpUrl(urlWebP);
      setDownloadLinkWebP(urlWebP);
    } catch (error) {
      console.error('Error during WebP conversion:', error);
    } finally {
      setLoading(false);
    }
  };

  
  const convertToMP4 = async () => {
    if (!file || !fileType.startsWith('video/')) {
      console.error('No video selected or file is not a video.');
      return;
    }
  
    setLoading(true);
    setProgress(0);
  
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
  
      await ffmpeg.writeFile('input.mp4', uint8Array);
  
      // Run the conversion command with corrected parameters
      await ffmpeg.exec([
        '-ss', '00:00:00', // Start from the beginning
        '-i', 'input.mp4',
        '-r', '30',        // Output frame rate
        '-filter:v', 'fps=30', // Ensure correct frame rate
        '-c:v', 'libx264', // Codec
        '-crf', '23',      // Quality setting
        '-an',             // No audio
        'output.mp4'
      ]);
      
  
      // Read the result from FFmpeg's virtual filesystem
      const resultMP4 = await ffmpeg.readFile('output.mp4');
  
      // Create a Blob and URL for the output video
      const blobMP4 = new Blob([resultMP4.buffer], { type: 'video/mp4' });
      const urlMP4 = URL.createObjectURL(blobMP4);
  
      setMp4Url(urlMP4);
      setDownloadLinkMP4(urlMP4);
  
      console.log('Conversion successful.');
    } catch (error) {
      console.error('Error during MP4 conversion:', error);
    } finally {
      setLoading(false);
    }
  };
  
 
  
  

  return ready ? (
    <div className="App">
      <header className="App-header">
        <h1>Convert File</h1>
      </header>

      <main className="App-content">
        <input type="file" accept="image/*,video/*" onChange={handleFileChange} />

        {fileType.startsWith('image/') && (
          <div>
            <button onClick={convertToWebP} disabled={loading}>Convert to WebP</button>
            {loading && <p>Progress: {progress}%</p>}
            {webpUrl && (
              <div>
                <h3>Converted WebP Image:</h3>
                <img src={webpUrl} alt="Converted to WebP" width="250" />
                <br />
                <a href={downloadLinkWebP} download="converted-image.webp">
                  <button>Download WebP Image</button>
                </a>
              </div>
            )}
          </div>
        )}

        {fileType.startsWith('video/') && (
          <div>
            <button onClick={convertToMP4} disabled={loading}>Convert to MP4 (H.265)</button>
            {loading && <p>Progress: {progress}%</p>}
            {mp4Url && (
              <div>
                <h3>Converted MP4 Video:</h3>
                <video controls width="250" src={mp4Url}></video>
                <br />
                <a href={downloadLinkMP4} download="converted-video.mp4">
                  <button>Download MP4 Video</button>
                </a>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  ) : (
    <p>Loading...</p>
  );
}

export default App;
