/*
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";

const Update = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: '',
    Birth_date: '',
    Number: '',
    Phone_Number: '',
    Zone: '',
    Position: ''
  });
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from('players')
          .select('Name, Birth_date, Number, Phone_Number, Zone, Position')
          .eq('id', id)
          .single();

        if (error) {
          console.error('Error fetching data:', error);
          navigate('/view', { replace: true });
        } else {
          setFormData(data);
        }
      } catch (err) {
        console.error('Unexpected error fetching data:', err);
        navigate('/view', { replace: true });
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {Name, Birth_date, Number, Phone_Number, Zone, Position } = formData;

    if (!Name || !Birth_date || !Number || !Phone_Number || !Zone || !Position) {
      setFormError('Please fill in all the fields correctly.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('players')
        .update({Name, Birth_date, Number, Phone_Number, Zone, Position })
        .eq('id', id);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error updating the data.');
      } else {
        console.log('Update successful:', data);
        setFormError(null);
        navigate('/view');
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key}>
            <label htmlFor={key}>{key.replace('_', ' ')}:</label>
            <input
              type={key === 'Birth_date' ? 'date' :  key === 'Number' || key === 'Phone_Number' ? 'number' : 'text'}
              id={key}
              value={formData[key]}
              onChange={handleChange}
            />
          </div>
        ))}
        <button>Update</button>
        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Update;
*/

import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";

const Update = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: '',
    Birth_date: '',
    Number: '',
    Phone_Number: '',
    Zone: '',
    Position: '',
    group_id: ''
  });
  const [formError, setFormError] = useState(null);
  const [groups, setGroups] = useState([]);

  // Fetch player data and available groups
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the player data
        const { data: playerData, error: playerError } = await supabase
          .from('players')
          .select('Name, Birth_date, Number, Phone_Number, Zone, Position, group_id')
          .eq('id', id)
          .single();

        if (playerError) {
          console.error('Error fetching player data:', playerError);
          navigate('/view', { replace: true });
        } else {
          setFormData(playerData);
        }

        // Fetch group_ids from user_groups
        const { data: userGroups, error: userGroupsError } = await supabase
          .from('user_groups')
          .select('group_id');

        if (userGroupsError) {
          console.error('Error fetching user groups:', userGroupsError);
          return;
        }

        const groupIds = userGroups.map(userGroup => userGroup.group_id);

        // Fetch groups based on the retrieved group_ids
        const { data: groupsData, error: groupsError } = await supabase
          .from('groups')
          .select('id, name')
          .in('id', groupIds);

        if (groupsError) {
          console.error('Error fetching groups:', groupsError);
        } else {
          setGroups(groupsData);
        }
      } catch (err) {
        console.error('Unexpected error fetching data:', err);
        navigate('/view', { replace: true });
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { Name, Birth_date, Number, Phone_Number, Zone, Position, group_id } = formData;

    if (!Name || !Birth_date || !Number || !Phone_Number || !Zone || !Position || !group_id) {
      setFormError('Please fill in all the fields correctly.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('players')
        .update({ Name, Birth_date, Number, Phone_Number, Zone, Position, group_id })
        .eq('id', id);

      if (error) {
        console.error('Supabase error:', error);
        setFormError('Error updating the data.');
      } else {
        console.log('Update successful:', data);
        setFormError(null);
        navigate('/view');
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setFormError('Unexpected error occurred.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).filter(key => key !== 'group_id').map((key) => (
          <div key={key}>
            <label htmlFor={key}>{key.replace('_', ' ')}:</label>
            <input
              type={key === 'Birth_date' ? 'date' : key === 'Number' || key === 'Phone_Number' ? 'number' : 'text'}
              id={key}
              value={formData[key]}
              onChange={handleChange}
            />
          </div>
        ))}

        <label htmlFor="group_id">Group:</label>
        <select
          id="group_id"
          value={formData.group_id}
          onChange={handleChange}
        >
          <option value="">Select a group</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>

        <button>Update</button>
        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Update;
