import React, { useState, useEffect } from 'react';
import supabase from "../config/supabaseClient";

// Import the image
import logo from '../images/logo.jpg';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Home = () => {
  const [lastGame, setLastGame] = useState(null);
  const [nextGame, setNextGame] = useState(null);
  const [practicesByDay, setPracticesByDay] = useState({});
  const [stats, setStats] = useState({ goals: 0, concededGoals: 0, points: 0 });
  const [showStatsOnly, setShowStatsOnly] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState('league');
  const [lastGameTeams, setLastGameTeams] = useState('');

  useEffect(() => {
    const fetchGames = async () => {
      const { data: games, error } = await supabase
        .from('game')
        .select(`
          *,
          adversaries (Adversarie)
        `);

      if (error) {
        console.error('Error fetching games:', error);
        return;
      }

      const now = new Date();

      // Combine Date and Time into a Date object for each game
      const gamesWithDateTime = games.map(game => {
        const dateTime = new Date(`${game.Date}T${game.Time}`);
        return { ...game, dateTime };
      });

      // Filter out the last game and next game
      const pastGames = gamesWithDateTime.filter(game => game.dateTime <= now);
      const futureGames = gamesWithDateTime.filter(game => game.dateTime > now);

      // Get the most recent past game (last game)
      if (pastGames.length > 0) {
        const lastGame = pastGames.reduce((latest, game) =>
          game.dateTime > latest.dateTime ? game : latest
        );
        setLastGame(lastGame);
        fetchLastGameTeams(lastGame.id); // Fetch teams for the last game
      }

      // Get the earliest future game (next game)
      if (futureGames.length > 0) {
        const nextGame = futureGames.reduce((earliest, game) =>
          game.dateTime < earliest.dateTime ? game : earliest
        );
        setNextGame(nextGame);
      }
    };

    const fetchLastGameTeams = async (gameId) => {
      const { data: results, error } = await supabase
        .from('game_results')
        .select('my_team, adversary_team')
        .eq('game_id', gameId);

      if (error) {
        console.error('Error fetching game results:', error);
        return;
      }

      if (results.length > 0) {
        const { my_team, adversary_team } = results[0];
        setLastGameTeams(`${my_team} - ${adversary_team}`);
      } else {
        setLastGameTeams('No result available');
      }
    };

    const fetchPractices = async () => {
      const { data: practices, error } = await supabase
        .from('practices')
        .select('*');

      if (error) {
        console.error('Error fetching practices:', error);
        return;
      }

      // Get the current date
      const now = new Date();

      // Calculate the start and end of the current week (Sunday to Saturday)
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - now.getDay()); // Set to the most recent Sunday
      startOfWeek.setHours(0, 0, 0, 0); // Start at midnight

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to the upcoming Saturday
      endOfWeek.setHours(23, 59, 59, 999); // End at the last moment of the day

      // Filter practices to include only those within the current week
      const practicesThisWeek = practices.filter(practice => {
        const practiceDate = new Date(practice.date);
        return practiceDate >= startOfWeek && practiceDate <= endOfWeek;
      });

      // Organize practices by day of the week
      const practicesByDay = practicesThisWeek.reduce((acc, practice) => {
        const practiceDate = new Date(practice.date);
        const day = daysOfWeek[practiceDate.getDay()]; // Get the weekday name
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(practice);
        return acc;
      }, {});

      setPracticesByDay(practicesByDay);
    };

    const fetchStats = async () => {
      const { data: results, error } = await supabase
        .from('game_results')
        .select('my_team, adversary_team, points')
        .eq('competition', selectedCompetition);

      if (error) {
        console.error('Error fetching game results:', error);
        return;
      }

      const goals = results.reduce((sum, result) => sum + result.my_team, 0);
      const concededGoals = results.reduce((sum, result) => sum + result.adversary_team, 0);
      const points = results.reduce((sum, result) => sum + result.points, 0);

      setStats({ goals, concededGoals, points });
    };

    fetchGames();
    fetchPractices();
    fetchStats();
  }, [selectedCompetition]);

  const formatDateTime = (dateTime) => {
    return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  return (
    <div style={styles.container}>
      {showStatsOnly ? (
        <div style={styles.statsSection}>
          <h2>Filter Results</h2>
          <div style={styles.filterGroup}>
            <label>
              <input
                type="radio"
                value="league"
                checked={selectedCompetition === 'league'}
                onChange={() => setSelectedCompetition('league')}
              />
              League
            </label>
            <label>
              <input
                type="radio"
                value="cup"
                checked={selectedCompetition === 'cup'}
                onChange={() => setSelectedCompetition('cup')}
              />
              Cup
            </label>
            <label>
              <input
                type="radio"
                value="friendly"
                checked={selectedCompetition === 'friendly'}
                onChange={() => setSelectedCompetition('friendly')}
              />
              Friendly
            </label>
          </div>

          <div style={styles.statsBoxes}>
            <div style={styles.statsBox}>
              <h3>Goals</h3>
              <p>{stats.goals}</p>
            </div>
            <div style={styles.statsBox}>
              <h3>Conceded Goals</h3>
              <p>{stats.concededGoals}</p>
            </div>
            <div style={styles.statsBox}>
              <h3>Points</h3>
              <p>{stats.points}</p>
            </div>
          </div>

          <button onClick={() => setShowStatsOnly(false)} style={styles.button}>Show All</button>
        </div>
      ) : (
        <div>
          <div style={styles.header}>
            <img
              src={logo}
              alt="Game Logo"
              style={styles.image}
            />
            <div style={styles.gameInfo}>
              <h2>Last Game</h2>
              {lastGame ? (
                <div>
                  <p><strong>Journey:</strong> {lastGame.Journey}</p>
                  <p><strong>Date & Time:</strong> {formatDateTime(lastGame.dateTime)}</p>
                  <p><strong>Adversarie:</strong> {lastGame.adversaries.Adversarie}</p>
                  <p><strong>Teams:</strong> {lastGameTeams}</p>
                </div>
              ) : (
                <p>No past games available.</p>
              )}

              <h2>Next Game</h2>
              {nextGame ? (
                <div>
                  <p><strong>Journey:</strong> {nextGame.Journey}</p>
                  <p><strong>Date & Time:</strong> {formatDateTime(nextGame.dateTime)}</p>
                  <p><strong>Adversarie:</strong> {nextGame.adversaries.Adversarie}</p>
                </div>
              ) : (
                <p>No upcoming games available.</p>
              )}
            </div>
          </div>

          <div style={styles.calendar}>
            <h2>Practice Schedule for This Week</h2>
            <div style={styles.weekGrid}>
              {daysOfWeek.map(day => (
                <div key={day} style={styles.day}>
                  <h3>{day}</h3>
                  {practicesByDay[day] && practicesByDay[day].length > 0 ? (
                    practicesByDay[day].map((practice, index) => (
                      <div key={index} style={styles.practice}>
                        <p><strong>Date:</strong> {new Date(practice.date).toLocaleDateString()}</p>
                        <p><strong>Time:</strong> {practice.time}</p>
                        <p><strong>Weather:</strong> {practice.weather}</p>
                      </div>
                    ))
                  ) : (
                    <p>No practices</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <button onClick={() => setShowStatsOnly(true)} style={styles.button}>Show Stats Only</button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap', // Enable wrapping
  },
  image: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    objectFit: 'cover',
    marginBottom: '20px',
    marginRight: '20px', // Add margin to create space between the image and the text
  },
  gameInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: '300px',
  },
  calendar: {
    width: '100%',
    maxWidth: '800px',
  },
  weekGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '10px',
  },
  day: {
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '8px',
  },
  practice: {
    borderBottom: '1px solid #ddd',
    padding: '5px 0',
  },
  statsSection: {
    textAlign: 'center',
    marginTop: '20px',
  },
  filterGroup: {
    marginBottom: '20px',
  },
  statsBoxes: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '600px',
    margin: '0 auto',
    marginBottom: '20px',
  },
  statsBox: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    width: '150px',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  // Media queries for responsive design
  '@media (max-width: 768px)': {
    header: {
      flexDirection: 'column', // Stack elements vertically
    },
    weekGrid: {
      gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns on mobile
    },
  },
  '@media (max-width: 480px)': {
    weekGrid: {
      gridTemplateColumns: '1fr', // 1 column on smaller mobile devices
    },
  },
};

export default Home;
