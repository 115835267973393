/*
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const DisplayExercises = () => {
  const [exercises, setExercises] = useState([]); // State to store fetched exercises
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const navigate = useNavigate(); // React Router's hook to navigate to different routes

  // Fetch exercises from Supabase
  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises') // Your table name
          .select('id, file_url, resume'); // Select the required columns

        if (error) {
          throw error;
        }

        setExercises(data); // Set fetched exercises to the state
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExercises();
  }, []); // Empty dependency array ensures this runs once on component mount

  // Handle click to navigate to the details page
  const handleViewDetails = (id) => {
    navigate(`/exercise-details/${id}`); // Navigate to the details page with the exercise ID
  };

  return (
    <div className="page display-exercises">
      <h1>Uploaded Exercises</h1>
      
      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      <div className="exercises-container">
        {exercises.length > 0 ? (
          exercises.map((exercise) => (
            <div 
              key={exercise.id} 
              className="exercise-item" 
              onClick={() => handleViewDetails(exercise.id)} // Navigate to details on click
            >
              <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
              <p className="exercise-resume">{exercise.resume}</p>
            </div>
          ))
        ) : (
          !loading && <p>No exercises found.</p>
        )}
      </div>
    </div>
  );
};

export default DisplayExercises;
*/
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

const DisplayExercises = () => {
  const [exercises, setExercises] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data, error } = await supabase
          .from('Exercises')
          .select('id, file_url, resume');

        if (error) {
          throw error;
        }

        setExercises(data);
      } catch (err) {
        setError('An error occurred: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExercises();
  }, []);

  const handleViewDetails = (id) => {
    navigate(`/exercise-details/${id}`);
  };

  return (
    <div className="page display-exercises">
      <style>{`
        .page {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        h1 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        .exercises-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          margin-top: 20px;
        }
        .exercise-item {
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          padding: 15px;
          text-align: center;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        .exercise-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        }
        .exercise-image {
          max-width: 100%;
          height: auto;
          border-radius: 4px;
        }
        .exercise-resume {
          margin-top: 10px;
          font-size: 14px;
          color: #555;
        }
      `}</style>

      <h1>Uploaded Exercises</h1>
      
      {loading && <p>Loading...</p>}

      {error && <p className="error">{error}</p>}

      <div className="exercises-container">
        {exercises.length > 0 ? (
          exercises.map((exercise) => (
            <div 
              key={exercise.id} 
              className="exercise-item" 
              onClick={() => handleViewDetails(exercise.id)}
            >
              <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
              <p className="exercise-resume">{exercise.resume}</p>
            </div>
          ))
        ) : (
          !loading && <p>No exercises found.</p>
        )}
      </div>
    </div>
  );
};

export default DisplayExercises;
