import React, { useEffect, useState } from 'react';
import supabase from "../config/supabaseClient";
import { useParams } from "react-router-dom";

const ExercisesListPage = () => {
  const { practiceId } = useParams(); // Get practiceId from the URL
  const [exercises, setExercises] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        // Fetch exercises related to the practice_id
        const { data, error } = await supabase
          .from('practice_exercises')
          .select('exercise_id, Exercises (file_url, resume)')
          .eq('practice_id', practiceId);

        if (error) {
          throw error;
        }

        setExercises(data.map(record => ({
          id: record.exercise_id,
          file_url: record.Exercises.file_url,
          resume: record.Exercises.resume
        })));
      } catch (err) {
        setError('An error occurred: ' + err.message);
      }
    };

    fetchExercises();
  }, [practiceId]);

  return (
    <div className="page exercises-list">
      <style>{`
        .page {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        h1 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        .exercises-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          margin-top: 20px;
        }
        .exercise-item {
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          padding: 15px;
          text-align: center;
        }
        .exercise-image {
          max-width: 100%;
          height: auto;
          border-radius: 4px;
        }
        .exercise-resume {
          margin-top: 10px;
          font-size: 14px;
          color: #555;
        }
        .error {
          color: red;
          text-align: center;
        }
      `}</style>

      <h1>Exercises for Practice ID: {practiceId}</h1>

      {error && <p className="error">{error}</p>}

      <div className="exercises-container">
        {exercises.length > 0 ? (
          exercises.map((exercise) => (
            <div key={exercise.id} className="exercise-item">
              <img src={exercise.file_url} alt="Exercise" className="exercise-image" />
              <p className="exercise-resume">{exercise.resume}</p>
            </div>
          ))
        ) : (
          <p>No exercises found for this practice.</p>
        )}
      </div>
    </div>
  );
};

export default ExercisesListPage;
