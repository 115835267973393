import React, { useEffect, useState } from 'react';
import supabase from "../config/supabaseClient";
import Querie_practice from '../components/Querie_practice';  
import PracticeDetailPage from './PracticeDetailPage';  // Import the PracticeDetailPage component
import '../index2.css';  

const ViewPractices = () => {
  const [fetchError, setFetchError] = useState(null);
  const [practices, setPractices] = useState([]);
  const [orderBy, setOrderBy] = useState('date');  
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const { data, error } = await supabase
          .from('practices')  
          .select()
          .order(orderBy, { ascending: false });

        if (error) {
          throw error;
        }

        setPractices(data);
        setFetchError(null);
      } catch (error) {
        setFetchError('Could not fetch the practices: ' + (error.message || 'Unknown error'));
        setPractices([]);
      }
    };

    fetchPractices();
  }, [orderBy]);

  const handleViewDetails = (id) => {
    setSelectedPracticeId(id);  // Set the selected practice ID
  };

  const handleBack = () => {
    setSelectedPracticeId(null);  // Clear the selected practice ID to go back
  };

  return (
    <div className="page view-practices">
      {selectedPracticeId ? (
        <PracticeDetailPage practiceId={selectedPracticeId} onBack={handleBack} />
      ) : (
        <>
          {fetchError && (<p className="error">{fetchError}</p>)}
          <div className="order-by">
            <p>Order by:</p>
            <button onClick={() => setOrderBy('date')}>Date</button>
            <button onClick={() => setOrderBy('time')}>Time</button>
            <button onClick={() => setOrderBy('journey')}>Journey</button>
          </div>
          <div className="smoothie-grid">
            {practices.length === 0 ? (
              <p>No practices found.</p>
            ) : (
              practices.map(practice => (
                <div key={practice.id} className="practice-card">
                  <Querie_practice item={practice} />
                  <button className="view-details-button" onClick={() => handleViewDetails(practice.id)}>
                    View Details
                  </button>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewPractices;
