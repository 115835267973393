/*
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // Import useLocation and useNavigate
import supabase from "../config/supabaseClient";
import '../index2.css';  // Ensure this is imported for styling

const AddCallsPage = () => {
  const location = useLocation();  // Get query parameters from the URL
  const navigate = useNavigate();  // Initialize useNavigate
  const [players, setPlayers] = useState([]);
  const [gameId, setGameId] = useState(new URLSearchParams(location.search).get('gameId'));
  const [calls, setCalls] = useState({});

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const { data, error } = await supabase
          .from('players')  // Ensure this matches your actual table name for players
          .select();

        if (error) {
          throw error;
        }

        setPlayers(data);
      } catch (error) {
        console.error('Could not fetch players:', error.message || 'Unknown error');
        setPlayers([]);
      }
    };

    fetchPlayers();
  }, []);

  const handleCallChange = (playerId, value) => {
    setCalls(prevCalls => ({ ...prevCalls, [playerId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const inserts = Object.keys(calls).map(playerId => ({
        game_id: gameId,
        player_id: parseInt(playerId),
        call: calls[playerId]
      }));

      const { error } = await supabase
        .from('call_players_game')  // Ensure this matches your actual table name
        .insert(inserts);

      if (error) {
        throw error;
      }

      navigate(`/game/${gameId}`);  // Redirect back to the game details page or wherever appropriate
    } catch (error) {
      console.error('Error inserting calls:', error.message || 'Unknown error');
    }
  };

  return (
    <div className="page add-calls">
      <h2>Add Calls to Players for Game {gameId}</h2>
      <form onSubmit={handleSubmit}>
        {players.length === 0 ? (
          <p>No players found.</p>
        ) : (
          players.map(player => (
            <div key={player.id} className="player-call">
              <label>
                {player.Name}:
                <input
                  type="text"
                  value={calls[player.id] || ''}
                  onChange={(e) => handleCallChange(player.id, e.target.value)}
                />
              </label>
            </div>
          ))
        )}
        <button type="submit">Submit Calls</button>
      </form>
    </div>
  );
};

export default AddCallsPage;
*/
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import supabase from "../config/supabaseClient";
import '../index2.css';

const AddCallsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [gameId, setGameId] = useState(new URLSearchParams(location.search).get('gameId'));
  const [calls, setCalls] = useState({});

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const { data, error } = await supabase
          .from('players')
          .select();

        if (error) {
          throw error;
        }

        setPlayers(data);
      } catch (error) {
        console.error('Could not fetch players:', error.message || 'Unknown error');
        setPlayers([]);
      }
    };

    fetchPlayers();
  }, []);

  const handleCallChange = (playerId, value) => {
    setCalls(prevCalls => ({ ...prevCalls, [playerId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const inserts = Object.keys(calls).map(playerId => ({
        game_id: gameId,
        player_id: parseInt(playerId),
        call: calls[playerId]
      }));

      const { error } = await supabase
        .from('call_players_game')
        .insert(inserts);

      if (error) {
        throw error;
      }

      navigate(`/game/${gameId}`);
    } catch (error) {
      console.error('Error inserting calls:', error.message || 'Unknown error');
    }
  };

  return (
    <div className="page add-calls">
      <h2>Add Calls to Players for Game {gameId}</h2>
      <form onSubmit={handleSubmit}>
        {players.length === 0 ? (
          <p>No players found.</p>
        ) : (
          players.map(player => (
            <div key={player.id} className="player-call">
              <label>
                {player.Name}:
                <select
                  value={calls[player.id] || ''}
                  onChange={(e) => handleCallChange(player.id, e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="summoned">Summoned</option>
                  <option value="not_summoned">Not Summoned</option>
                </select>
              </label>
            </div>
          ))
        )}
        <button type="submit">Submit Calls</button>
      </form>
    </div>
  );
};

export default AddCallsPage;
